import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({}))

function SettingsMalls() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef

  return (
    <Page
      title={__('مواقع العمل', 'Locations')}
      left={() => (
        <Forms
          onRef={ref => formRef = ref}
          title={__('اضافة  موقع عمل جديد', 'Add New Location')}
          editTitle={__('تعديل  موقع العمل', 'Edit location Information')}
          endpoint={'mall'}
          params={{
            include: 'user,country'
          }}
          fields={[
            [
              {
                title: __(' اسم موقع العمل (بالانجليزية) ', 'Location Name (en)'),
                field: 'name_en',
                type: 'string',
                rules: ['required'],
              },
              {
                title: __(' اسم موقع العمل (العربية) ', 'Location Name (ar)'),
                field: 'name_ar',
                type: 'string',
                rules: ['required'],
              },
            ],
            {
              title: __('الدولة', 'Country'),
              field: 'country_id',
              type: 'dropdown',
              endpoint: 'country',
              params: {
                limit: 1000
              },
              val: 'id',
              label: 'name_en',
              rules: ['required'],
            },
            {
              title: __('اسم المشرف', 'Supervisor Name'),
              field: 'user_id',
              type: 'dropdown',
              endpoint: 'users',
              params: {
                limit: 1000
              },
              val: 'id',
              label: 'name',
              rules: ['required'],
            },
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >

      <DataTable
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة مواقع العمل', 'Listing Locations')}
        endpoint={'mall'}
        summary={'mall'}
        // buttons={['edit', 'delete']}
        params={{
          include: 'user,country,'
        }}
        limit={200}
        // pagination={false}
        settings={{
          selection: true,
          deleteSelected:true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        columns={[
          {
            title: __('الاسم', 'Name'),
            field: 'name_en',
            type: 'string',
            summary: 'count',
            sum_after: __('مواقع العمل', 'Locations'),

          },
          {
            title: __('الدولة', 'Country'),
            field: 'country.name_en',
            type: 'string',
            sorting: false
          },
          {
            title: __('المشرف', 'Supervisor'),
            field: 'user.name',
            type: 'string',
            sorting: false
          },
          {
            title: __('المخزن', 'Stock'),
            onClick: {
              link: ['settings/malls/{id}', 'id'],
              title: __('المخزن', 'Supply Stock'),
              type: 'button',
              value: __('المخزن', 'Stock'),
            },
            sorting:false,
          },
        ]}
        search={[
          {
            title: __(' اسم موقع العمل (بالانجليزية) ', 'Location Name (en)'),
            field: 'name_en',
            type: 'string',
            filter: 'like'
          },
          {
            title: __(' اسم موقع العمل (العربية) ', 'Location Name (ar)'),
            field: 'name_ar',
            type: 'string',
            filter: 'like'
          },
          {
            title: __(' اسم الدولة ', '  Country Name'),
            field: 'country_id',
            type: 'dropdown',
            endpoint: 'country',
            params: {
              limit: 1000
            },
            multiple: true,
            val: 'id',
            label: 'name_en',
          },
        ]}
      />
    </Page>
  )
}

export default SettingsMalls
