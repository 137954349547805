/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import LocalCarWashOutlinedIcon from '@material-ui/icons/LocalCarWashOutlined'
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined'
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined'
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag'
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined'
import ScatterPlotOutlinedIcon from '@material-ui/icons/ScatterPlotOutlined'
import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined'
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined'
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import ListIcon from '@material-ui/icons/List'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import AssessmentIcon from '@material-ui/icons/Assessment'
import MoneyIcon from '@material-ui/icons/Money'
import StoreIcon from '@material-ui/icons/Store'
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';

import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"
import {DataTable, Page} from "../../admin-utils"

export default [
  {
    subheader: __('القائمة الرئيسية ', 'Main Menu'),
    items: [
      {
        title: __('الصفحة الرئيسية', 'Home'),
        href: '/dashboard',
        icon: DashboardIcon
      },
    ]
  }, {
    subheader: __('تطبيق الموظف', 'Employee App'),
    items: [
      {
        title: __('الطلبات', 'Orders'),
        href: '/orders',
        icon: LocalCarWashOutlinedIcon
      },
      {
        title: __('تسليم يومية', 'Hand Over'),
        href: '/hand/over',
        icon: SwapVerticalCircleOutlinedIcon
      },
      {
        title: __('تقارير الارباح', 'Revenue Reports'),
        href: '/revenue/reports',
        icon: AssessmentIcon
      },
      {
        title: __('المصاريف', 'Expenses'),
        href: '/expenses',
        icon: MoneyIcon
      },
      {
        title: __('انواع السيارات', 'Car Types'),
        href: '/settings/car/types',
        icon: DirectionsCarOutlinedIcon
      },
      {
        title: __('انواع الخدمات', 'Services Types'),
        href: '/settings/service/types',
        icon: ListAltOutlinedIcon
      },
      {
        title: __('الخدمات', 'Services'),
        href: '/settings/services',
        icon: CommuteOutlinedIcon
      },
      {
        title: __('الاغطية', 'Covers'),
        href: '/settings/covers',
        icon: DragIndicatorIcon
      },
      // {
      //   title: __('مراقب المشرفين', 'Area Supervisor'),
      //   href: '/area/supervisor',
      //   icon: LocalCarWashOutlinedIcon
      // },
    ]
  }, {
    subheader: __('تطبيق العميل ', 'Client App'),
    items: [
      {
        title: __('العملاء', 'Clients'),
        href: '/client/clients',
        icon: AssignmentIndIcon
      },
      {
        title: __('الطلبات', 'Orders'),
        href: '/client/orders',
        icon: PlaylistAddCheckIcon
      },
      {
        title: __('الأقسام', 'Categories'),
        href: '/client/categories',
        icon: ListIcon
      },
      {
        title: __('المنتجات', 'Products'),
        href: '/client/products',
        icon: AddShoppingCartIcon
      },
      {
        title: __('الإضافات', 'Options'),
        href: '/client/options',
        icon: AddBoxIcon
      },
      {
        title: __('اعدادات تطبيق العميل', 'Client App Settings'),
        href: '/settings/app',
        icon: SettingsBrightnessIcon
      },
    ]
  },  {
    subheader: __(' المخازن', 'Stocks'),
    items: [
      {
        title: __('التوريدات', 'Supplies'),
        href: '/settings/supplies',
        icon: ScatterPlotOutlinedIcon
      },
      {
        title: __('فواتير التوريد', 'Supply Invoices'),
        href: '/supply/invoice',
        icon: DescriptionOutlinedIcon,
      },
      {
        title: __('المستودع الرئيسي', 'Warehouse'),
        href: '/warehouse',
        icon: StoreIcon,
      },
      {
        title: __(' مواقع العمل', 'Locations'),
        href: '/settings/malls',
        icon: StoreMallDirectoryOutlinedIcon
      },
      {
        title: __('التوريد لمواقع العمل', 'Locations Supply'),
        href: '/mall/supply',
        icon: DescriptionOutlinedIcon,
      },
      {
        title: __('مخازن مواقع العمل', 'Locations Stocks'),
        href: '/mall/stock',
        icon: AccountTreeOutlinedIcon
      },
      {
        title: __('الخارج من مخازن مواقع العمل', 'Locations Stocks Out'),
        href: '/stock/out',
        icon: BallotOutlinedIcon
      },
      {
        title: __('التالف', 'Damaged'),
        href: '/mall/damaged',
        icon: EventBusyIcon
      },
    ]
  },{
    subheader: __(' الاعدادات', 'Settings'),
    items: [
      {
        title: __('البلاد', 'Countries'),
        href: '/settings/countries',
        icon: OutlinedFlagIcon,
      },
      {
        title: __('مشرفي المناطق', 'Area Supervisors'),
        href: '/settings/areas',
        icon: PersonPinCircleIcon
      },
    ]
  },
  {
    subheader: __(' الادارة', 'User Management'),
    items: [
      {
        title: __('الادوار و الصلاحيات', 'Roles'),
        href: '/user/management/roles',
        icon: ContactsOutlinedIcon
      },
      {
        title: __('المستخدمين', 'Users'),
        href: '/user/management/users',
        icon: PersonAddOutlinedIcon
      }
    ]
  }
]
