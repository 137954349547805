/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import LocalCarWashOutlinedIcon from '@material-ui/icons/LocalCarWashOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';

import {FormattedMessage} from "react-intl"
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import __ from "../../admin-utils/LanguageHelper"

export default [
  {
    subheader: __('القائمة الرئيسية ', 'Main Menu'),
    items: [
      {
        title: __('الطلبات', 'Orders'),
        href: '/morders',
        icon: LocalCarWashOutlinedIcon
      },
    ]
  },
]
