import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {Doughnut} from 'react-chartjs-2'
import {makeStyles, useTheme} from '@material-ui/styles'
import LoadingOverlay from "react-loading-overlay"
import {CardContent, Divider, Typography} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2)
  },
  emptySpace: {
    height: 180
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}))

function ChartWidget({data, field, className, ...rest}) {
  const classes = useStyles()
  const theme = useTheme()

  if (!data) {
    return <LoadingOverlay
      active={true}
      spinner
    >
      <div className={classes.emptySpace}/>
    </LoadingOverlay>
  }

  const chartData = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 8,
        borderColor: theme.palette.common.white,
        hoverBorderColor: theme.palette.common.white
      }
    ],
    labels: []
  }

  if (field) {
    data = data[field]
  }

  for (const item of data) {
    chartData.datasets[0].data.push(item.value)
    chartData.datasets[0].backgroundColor.push(item.color)
    chartData.labels.push(item.label)
  }

  const options = {
    responsive: true,
    animation: false,
    cutoutPercentage: 80,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.common.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data['labels'][tooltipItem['index']]
          const value = data['datasets'][0]['data'][tooltipItem['index']]

          return `${label}: ${value}`
        }
      }
    }
  }

  return (
    <>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Doughnut
          data={chartData}
          options={options}
        />
      </div>

      <Divider />
      <div className={classes.statsContainer}>
        {data.map(item => (
          <div
            className={classes.statsItem}
            key={item.id}
          >
            <Typography
              align="center"
              component="h6"
              gutterBottom
              variant="overline"
            >
              {item.label}
            </Typography>
            <Typography
              align="center"
              variant="h4"
            >
              {item.value}
            </Typography>
          </div>
        ))}
      </div>
    </>
  )
}

ChartWidget.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array
}

export default ChartWidget
