import React, {useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {Slide, Typography} from "@material-ui/core"
import __ from "../../admin-utils/LanguageHelper"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {useSelector} from "react-redux"
import Link from "@material-ui/core/Link"
import {update as APIUpdate} from "../../admin-utils/API"
import Snackbar from "@material-ui/core/Snackbar"

const useStyles = makeStyles((theme) => ({}))

function CountrySelector({}) {
  const classes = useStyles()
  const [showChange, setShowChange] = useState(false)
  const session = useSelector((state) => state.session)
  const [loading, setLoading] = useState(false)

  const [currentCountry, setCurrentCountry] = useState(session.user.current_country)

  const country = session.user.countries.find(i => i.id == currentCountry)

  const changeCountry = (event) => {
    let country = event.target.value
    setCurrentCountry(country)
    setShowChange(false)
    setLoading(true)

    // Save current country
    APIUpdate('users', session.user.id, {current_country: country}, session.token).then((data) => {
      setLoading(false)
    })
  }

  const handleClick = (e) => {
    e.preventDefault()
    setShowChange(!showChange)
  }

  // if(!currentCountry) {
  //   return null
  // }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: '#f1f1f1', padding: 20}}>
        <Typography variant={"body2"} style={{color: '#000', marginRight: 5}}>
          {__('تصفح البيانات الخاصة بـ', 'Browse data of')}
        </Typography>

        <Link href="#" onClick={handleClick} variant={"body2"} style={{color: '#3CA0D3',}}>
          {(currentCountry == 1000 || !country) ? __("كل الدول", "All Countries") : __(country.name_ar, country.name_en)}
        </Link>
      </div>

      {showChange &&
      <div style={{
        backgroundColor: '#fff',
        margin: 15,
        borderRadius: 4,
        padding: 10
      }}>
        <Select
          value={currentCountry}
          onChange={changeCountry}
          variant="outlined"
          size={'small'}
          margin="none"
          fullWidth
        >
          <MenuItem value={1000}>{__("كل الدول", "All Countries")}</MenuItem>
          {session.user.countries.map(country => (
            <MenuItem value={country.id}>{__(country.name_ar, country.name_en)}</MenuItem>
          ))}
        </Select>
      </div>}

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        key={`loader`}
        open={loading}
        TransitionComponent={Slide}
        message={__('جاري حفظ البيانات...', 'Saving data...')}
      />
    </>
  )
}


export default CountrySelector
