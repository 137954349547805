import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({}))

function SettingsCountries() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef

  return (
    <Page
      title={__('الدول', 'Countries')}
      left={() => (
        <Forms
          onRef={ref => formRef = ref}
          title={__('اضافة دولة جديدة', 'Add New Country')}
          editTitle={__('تعديل الدولة', 'Edit Country')}
          endpoint={'country'}
          fields={[
            {
              title: __('يمكنك رفع علم الدولة', 'Upload Country Flag'),
              field: 'image',
              type: 'image',
              rules: ['required']
            } ,
            [
              {
                title: __('الاسم (بالانجليزية)', 'Name (en)'),
                field: 'name_en',
                type: 'string',
                rules: ['required'],
              },
              {
                title: __('الاسم (بالعربية)', 'Name (ar)'),
                field: 'name_ar',
                type: 'string',
                rules: ['required'],
              },
            ],
            [
              {
                title: __('العملة (بالانجليزية)', 'Currency (en)'),
                field: 'currency_en',
                type: 'string',
                rules: ['required'],
              },
              {
                title: __('العملة (بالعربية)', 'Currency (ar)'),
                field: 'currency_ar',
                type: 'string',
                rules: ['required'],
              },
            ],
            {
              title: __('الكود', 'Code'),
              field: 'code',
              type: 'string',
            },
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >

      <DataTable
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة الدول', 'Listing Countries')}
        endpoint={'country'}
        summary={'country'}
        buttons={['edit', 'delete']}
        settings={{
          selection: true,
          deleteSelected:true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        columns={[
          {
            title: __('العلم', 'Flag'),
            field: 'image',
            type: 'image',
            width: 80
          },
          {
            title: __('الاسم', 'Name'),
            field: 'name_en',
            type: 'string',
            summary: 'count',
            sum_after: __('الدول', 'Countries'),

          },
          // {
          //   title: <FormattedMessage id={'tables.countries.name'} defaultMessage={'Name (Arabic)'}/>,
          //   field: 'name_ar',
          //   type: 'string'
          // },
          {
            title: __('العملة', 'Currency'),
            field: 'currency_en',
            type: 'string'
          },
          // {
          //   title: <FormattedMessage id={'tables.countries.currency'} defaultMessage={'currency (Arabic)'}/>,
          //   field: 'currency_ar',
          //   type: 'string'
          // },
          {
            title: __('الكود', 'Code'),
            field: 'code',
            type: 'string'
          },
        ]}
        search={[
          {
            title: __(' اسم الدولة (بالانجليزية) ', 'Country Name (en)'),
            field: 'name_en',
            type: 'string',
            filter: 'like'
          },
          {
            title: __(' اسم الدولة (بالعربية) ', 'Country Name (ar)'),
            field: 'name_ar',
            type: 'string'
          },
        ]}
      />
    </Page>
  )
}

export default SettingsCountries
