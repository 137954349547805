import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import Img from "react-image";
import CircularProgress from "@material-ui/core/CircularProgress";
import StarIcon from "@material-ui/icons/Star";
import Chip from "@material-ui/core/Chip";
import { grey, yellow } from "@material-ui/core/colors";
import moment from "moment";
import * as numeral from "numeral";
import { Else, If, Then } from "react-if";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import PermMedia from "@material-ui/icons/PermMedia";
import ReactHtmlParser from "react-html-parser";
import __ from "../../LanguageHelper";
import Truncate from "react-truncate";
import { Page } from "../../index";
import Box from "@material-ui/core/Box";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import {
  BsClipboard,
  FaClipboard,
  FaRegClipboard,
  HiOutlineClipboard,
  HiOutlineClipboardCheck
} from "react-icons/all";

const useStyles = makeStyles(theme => ({
  root: {},
  img: {
    maxWidth: "100%"
  },
  avatar: {
    borderRadius: "50%",
    maxWidth: "100%",
    border: "2px solid" + theme.palette.primary.main
  },
  titleCell: {
    width: "50%",
    maxWidth: 150
  },
  valueCell: {
    textAlign: "right"
  },
  centerCell: {
    textAlign: "center"
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  emptyStar: {
    color: grey[500]
  },
  filledStar: {
    color: yellow[700]
  },
  inlineCell: {
    borderBottomWidth: 0,
    paddingBottom: 0
  }
}));

const DetailsTable = ({
  data = {},
  rows = [],
  size,
  inline,
  strips = true
}) => {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  if (Array.isArray(data)) {
    data = data[0];
  }

  if (Object.keys(data).length > 0) {
    rows.map((row, index) => {
      if (!Array.isArray(row)) {
        row = [row];
      }

      row.map(r => {
        if (r.field) {
          r.value = _.get(data, r.field);
        }
      });
    });
  }

  const renderValue = row => {
    let rowValue = row.value;
    let value,
      rawText = row.value;

    function getFilename(url) {
      const filename = decodeURIComponent(
        new URL(url).pathname.split("/").pop()
      );
      if (!filename) return "index.html"; // some default filename
      return filename;
    }

    function filenameWithoutExtension(url) {
      return getFilename(url).replace(/^(.+?)(?:\.[^.]*)?$/, "$1");
    }

    const onMoreClick = () => {
      Page.pushView({
        stay: true,
        modal: true,
        drawerWidth: 800,
        direction: "right",
        view: (
          <Box p={2}>
            <Typography variant="body1" color="textPrimary">
              {ReactHtmlParser(row.value)}
            </Typography>
          </Box>
        )
      });
    };

    function getFileExtensionIcon(filename) {
      let regex = /(?:\.([^.]+))?$/;
      let ext = regex.exec(filename)[1];

      if (ext === "pdf") {
        return <PictureAsPdf />;
      } else {
        return <PermMedia />;
      }
    }

    switch (row.type) {
      case "image":
        value = (
          <Img
            src={row.value}
            alt={row.title}
            className={classes.img}
            loader={<CircularProgress color="secondary" />}
            {...row}
          />
        );
        break;
      case "avatar":
        value = (
          <Img
            src={row.value}
            alt={row.title}
            className={classes.avatar}
            loader={<CircularProgress color="secondary" />}
            {...row}
          />
        );
        break;
      case "title":
        value = <Typography variant="h4">{row.value}</Typography>;
        break;
      case "link":
        const link = row.link.replace("[value]", row.value);
        const embedLink = row.embedLink.replace("[value]", row.value);

        rawText = link;

        value = (
          <>
            {row.onClick ? (
              <a href={"javascript:;"} onClick={() => row.onClick(embedLink)}>
                <Typography variant="body1" color={"primary"}>
                  {row.value}
                </Typography>
              </a>
            ) : (
              <a href={link} target={"_blank"}>
                <Typography variant="body1" color={"primary"}>
                  {row.value}
                </Typography>
              </a>
            )}
          </>
        );
        break;
      case "html":
        value = (
          <Typography variant="body1" style={{ color: "#616161" }}>
            {row.maxLines ? (
              <>
                <Truncate
                  lines={row.maxLines}
                  ellipsis={
                    <>
                      ...
                      <div>
                        <Button
                          variant="text"
                          size={"small"}
                          color="primary"
                          onClick={() => onMoreClick()}
                        >
                          {__("قراءة المزيد", "Read More")}
                        </Button>
                      </div>
                    </>
                  }
                >
                  {ReactHtmlParser(row.value)}
                </Truncate>
              </>
            ) : (
              ReactHtmlParser(row.value)
            )}
          </Typography>
        );
        break;
      case "currency":
        value = <strong>{numeral(row.value).format("0,0.00")}</strong>;
        break;
      case "date":
        value = moment(row.value).format("DD/MM/YYYY");
        break;
      case "mobile":
      case "whatsapp":
        const code = _.get(data, row.code, "");
        const mobile = `${code}${row.value.replace(/[٠-٩]/g, d =>
          "٠١٢٣٤٥٦٧٨٩".indexOf(d)
        )}`;
        let href = `tel:${mobile}`;
        if (row.type === "whatsapp") {
          href = `https://wa.me/${mobile}`;
        }

        value = (
          <a href={href} target={"_blank"}>
            <Typography variant="body1" color={"primary"}>
              {mobile}
            </Typography>
          </a>
        );
        break;
      case "stars":
        value = (
          <>
            {_.range(5).map(i => (
              <StarIcon
                className={
                  i < row.value ? classes.filledStar : classes.emptyStar
                }
              />
            ))}
          </>
        );
        break;
      case "chips":
      case "tags":
        if (!Array.isArray(row.value)) {
          rowValue = [row.value];
        }

        let Icon = null;

        return rowValue.map(item => {
          if (row.imageKey && item[row.imageKey]) {
            Icon = <Avatar alt={item[row.key]} src={item[row.imageKey]} />;
          }

          let label,
            labels = [];
          if (_.isArray(row.key)) {
            row.key.map(i => labels.push(_.get(item, i)));
            label = `${row.before || ""}${labels.join(
              row.separator || " - "
            )}${row.after || ""}`;
          } else {
            label = _.get(item, row.key);
          }

          return (
            <Chip
              label={label}
              color={"secondary"}
              variant={"outlined"}
              className={classes.chip}
              size="small"
              icon={Icon}
            />
          );
        });

      case "files":
        if (!Array.isArray(row.value)) {
          rowValue = [row.value];
        }

        return (
          <div style={{ marginTop: 10 }}>
            {rowValue.map(item => (
              <Chip
                label={filenameWithoutExtension(item)}
                color={"secondary"}
                variant={"default"}
                className={classes.chip}
                component="a"
                href={item}
                clickable
                icon={getFileExtensionIcon(item)}
                target={"_blank"}
              />
            ))}
          </div>
        );
      case "component":
        value = row.component && row.component(row.value);
        break;
      default:
        value = row.value;
    }

    if (row.options) {
      let option = row.options.find(i => i.id === value);
      if (option) {
        value = option.name;
      }
    }

    if (row.append) {
      value = (
        <>
          {value} {row.append}
        </>
      );
    }

    if (row.copy) {
      value = (
        <>
          {value}

          <div style={{ marginTop: 5 }}>
            <CopyToClipboard
              text={rawText}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 3000);
              }}
            >
              <Button
                color={copied ? "primary" : "secondary"}
                size={"small"}
                variant={copied ? "contained" : "outlined"}
                startIcon={
                  copied ? (
                    <HiOutlineClipboardCheck size={18} />
                  ) : (
                    <HiOutlineClipboard size={18} />
                  )
                }
              >
                <span style={{ marginTop: 2 }}>
                  {copied ? __("تم النسخ", "Copied") : __("نسخ", "Copy")}
                </span>
              </Button>
            </CopyToClipboard>
          </div>
        </>
      );
    }

    return value;
  };

  return (
    <>
      <Table size={size}>
        <TableBody>
          {rows.map((item, index) => (
            <>
              <If condition={inline}>
                <Then>
                  {() => {
                    if (!Array.isArray(item)) {
                      item = [item];
                    }

                    return (
                      <TableRow key={index}>
                        {item.map(i => (
                          <TableCell className={classes.inlineCell}>
                            <strong>{i.title}</strong>
                            {!i.titleOnly && (
                              <p>{i.value ? renderValue(i) : "...."}</p>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }}
                </Then>
                <Else>
                  {() =>
                    (item.hideEmpty && item.value) ||
                    (!item.hideEmpty && (
                      <TableRow
                        selected={strips && index % 2 !== 0}
                        key={index}
                      >
                        {item.title && (
                          <TableCell className={classes.titleCell}>
                            <strong>{item.title}</strong>
                          </TableCell>
                        )}
                        <TableCell
                          className={
                            item.center ? classes.centerCell : classes.valueCell
                          }
                          colSpan={item.full ? 2 : null}
                        >
                          {!item.titleOnly && (
                            <>{item.value ? renderValue(item) : "...."}</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </Else>
              </If>
            </>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

DetailsTable.propTypes = {
  data: PropTypes.object
};

export default DetailsTable;
