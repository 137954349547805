import React, {useState} from 'react'
import {makeStyles} from "@material-ui/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing.unit,
  }
}))

const DetailsTabs = ({tabs = [], className}) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <Paper square className={clsx(classes.container, className)}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map(tab => (
            <Tab label={tab.title}/>
          ))}
        </Tabs>
      </Paper>

      {tabs.map(({content}, index) => (
        <>
          {activeTab === index && (
            <div role="tabpanel">
              {content && content()}
            </div>
          )}
        </>
      ))}
    </>
  )
}

export default DetailsTabs
