import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import {useParams} from "react-router"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({}))


function OrdersDetails() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef
  let {id} = useParams()
  const [updater, setUpdater] = useState(false)

  return (
    <Page
      title={__('تفاصيل الطلب', 'Order Details')}
      left={() => (
        <Forms
          hide
          id={id}
          onRef={ref => formRef = ref}
          endpoint={'order'}
          icon={'edit'}
          params={{
            include: 'user,mall,service,service_type,park'
          }}
          title={__('تعديل طلب', 'Edit Order')}
          editTitle={__('تعديل الطلب ', 'Edit Order')}
          fields={[
            {
              title: __('التاريخ', 'Date'),
              field: 'date',
              type: 'date',
              rules: ['required'],
            },
            [
              {
                title: __('البلد', 'Country'),
                field: 'country_id',
                type: 'dropdown',
                endpoint: 'country',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                rules: ['required'],
              },
              {
                title: __('موقع العمل', 'Location'),
                field: 'mall_id',
                type: 'dropdown',
                endpoint: 'mall',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                waitValue: 'country_id',
                rules: ['required'],
              },
            ],
            [
              {
                title: __('اسم الموظف', 'Employee Name'),
                field: 'user_id',
                type: 'dropdown',
                endpoint: 'users',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name',
                waitValue: 'mall_id',
                rules: ['required'],
              },
              {
                title: __('الخدمة', 'Service'),
                field: 'service_id',
                type: 'dropdown',
                endpoint: 'service',
                params: {
                  limit: 1000,
                  include: 'service_type,car'
                },
                val: 'id',
                keys: ['car.name_en', 'service_type.name_en', 'price'],
                separator: '-',
                waitValue: 'country_id',
                rules: ['required'],
              },
            ],
            [
              {
                title: __('رقم السيارة', 'Car Number'),
                field: 'car_number',
                type: 'numeric',
                rules: ['required'],
              },
              {
                title: __('طريقة الدفع', 'Payment Method'),
                field: 'payment_method',
                type: 'dropdown',
                rules: ['required',],
                options: [
                  {id: 'visa', name: __('كريديت', 'Credit')},
                  {id: 'cash', name: __('كاش', 'Cash')},
                ],
              },
            ],
            [
              {
                title: __('نسبة الخصم', 'Discount Percent'),
                field: 'discount_percent',
                type: 'numeric',
              },
              {
                title: __('الاجمالي', 'Total'),
                field: 'total',
                type: 'numeric',
                rules: ['required'],
              },
            ],
            // {
            //   title: __('الحالة', 'Status'),
            //   field: 'Status',
            //   type: 'dropdown',
            //   options: [
            //     {id: 'processing', name: __('جاري العمل', 'Processing')},
            //     {id: 'completed', name: __('تم الانتهاء', 'Completed')},
            //   ],
            // },
          ]}
          onFinish={() => {
            setUpdater(!updater)
          }}
        />
      )}
    >
      <DetailsContainer
        endpoint={'order'}
        params={{
          include: 'user,mall,mall.country,service,service.car,service.service_type,order_covers,service.service_covers,service.service_covers.cover'
        }}
        updater={updater}
      >
        <DetailsCard
          columns={5}
          title={__('تفاصيل الخدمة', 'Service Details')}
        >
          <DetailsTable
            rows={[
              // {
              //   title: __(' الحالة ', 'Status'),
              //   field: 'status',
              //   type: 'string',
              // },
              {
                title: __(' رقم السيارة ', 'Car Number'),
                field: 'car_number',
                type: 'numeric',
              },
              {
                title: __(' النوع ', 'Type'),
                field: __('service.car.name_ar', 'service.car.name_en'),
                type: 'string',
              },
              {
                title: __(' الخدمة ', 'Service'),
                field: __('service.service_type.name_ar', 'service.service_type.name_en'),
                type: 'string',
              },
              {
                title: __(' طريقة الدفع ', 'Payment Method'),
                field: 'payment_method',
                type: 'string',
                options: [
                  {id: 'visa', name: __('كريديت', 'Credit')},
                  {id: 'cash', name: __('كاش', 'Cash')},
                ],
              },
              {
                title: __(' السعر ', 'Price'),
                field: 'service.price',
                type: 'numeric',
              },
              {
                title: __(' نسبة الخصم ', 'Discount Percent'),
                field: 'discount_percent',
                type: 'numeric',
                append: ['%'],
              },
              {
                title: __(' الاجمالي ', 'Total'),
                field: 'total',
                type: 'numeric',
              },
            ]}
          />
        </DetailsCard>
        <DetailsCard
          columns={4}
          title={__('تفاصيل موقع العمل', 'Location Details')}
        >
          <DetailsTable
            rows={[
              {
                title: __(' موقع العمل ', 'Location'),
                field:__('mall.name_ar','mall.name_en'),
                type: 'string',
              },
              {
                title: __('المنطقة', 'Area Parking'),
                field: 'park.park',
                type: 'string',
              },
              {
                title: __(' البلد ', 'Country'),
                field:__('mall.country.name_ar','mall.country.name_en'),
                type: 'string',
              },
              {
                title: __(' اسم الموظف ', 'Employee Name'),
                field: 'user.name',
                type: 'string',
              },
              {
                title: __('الاغطية المجانية', 'Free Covers'),
                field: 'service.service_covers',
                type: 'tags',
                key: [
                  'cover.name_en',
                  'quantity'
                ],
                separator: ' (',
                after: ')'
              },
            ]}
          />
        </DetailsCard>
        <DetailsCard
          columns={3}
          title={__('تفاصيل العميل', 'Client Details')}
        >
          <DetailsTable
            rows={[
              {
                title: __('اسم العميل', 'Client Name'),
                field: 'client_name',
                type: 'string',
              },
              {
                title: __('هاتف العميل ', 'Client Phone'),
                field: 'client_phone',
                type: 'string',
              },
            ]}
          />
        </DetailsCard>
      </DetailsContainer>

      <Forms
        hide
        onRef={ref => formRef = ref}
        title={__('الاغطية المدفوعة', 'Paid Covers')}
        endpoint={'order_cover'}
        hidden={{
          order_id: id
        }}
        fields={[
          {
            title: __('الغطاء', 'Cover'),
            field: 'cover_id',
            type: 'dropdown',
            endpoint: 'cover',
            params: {
              limit: 1000
            },
            val: 'id',
            label: 'name_en',
            rules: ['required'],
          },
          [
            {
                title: __('الكمية', 'Quantity'),
              field: 'quantity',
              type: 'numeric',
              rules: ['required',],
            },
            {
              title: __('الاجمالي', 'Total'),
              field: 'total',
              type: 'numeric',
              rules: ['required',],
            },
          ]
        ]}
        onFinish={() => {
          tableRef.current.onQueryChange()
        }}
      />
      <DataTable
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('الاغطية', 'Covers')}
        endpoint={'order_cover'}
        summary={'order_cover'}
        params={{
          search: `order_id:${id}`,
          include: 'cover,country'
        }}
        addTitle={__('اضافة سعر الغطاء','Add Cover Price')}
        // buttons={['add','edit','delete']}
        columns={[
          {
            title: __('الغطاء', 'Cover'),
            field: 'cover.name_en',
            type: 'string',
            summary: 'count',
            sum_after: __('اغطية','Covers'),
            sorting: false
          },
          {
            title: __('الكمية', 'Quantity'),
            field: 'quantity',
            type: 'number',
          },
          {
            title: __('الاجمالي', 'Total'),
            field: 'total',
            type: 'money',
            summary: 'sum',
          },
        ]}
      />

    </Page>
  )
}

export default OrdersDetails
