import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import {update as APIUpdate, create as APICreate, remove_record as APIDelete} from "../../API"
import {useSelector} from "react-redux"

const useStyles = makeStyles(() => ({
  root: {}
}))

const ActionButton = ({title, action, endpoint, params, onFinish, color = "primary", show, method}) => {
  const classes = useStyles()
  const {token} = useSelector((state) => state.session)
  const [loading, setLoading] = useState(false)

  let buttonAction = action

  if (endpoint) {
    buttonAction = function () {
      setLoading(true)

      if(method === "delete") {
        APIDelete(endpoint, params.id, token).then(data => {
          setLoading(false)
          onFinish && onFinish(data)
        })
      }else{
        if(params.id) {
          APIUpdate(endpoint, params.id, params, token).then((data) => {
            setLoading(false)
            if(data) {
              onFinish && onFinish(data)
            }
          })
        }else{
          APICreate(endpoint, params, token).then((data) => {
            setLoading(false)
            if(data) {
              onFinish && onFinish(data)
            }
          })
        }
      }

    }
  }

  if (!show) return null

  return (
    <>
      <Button onClick={() => buttonAction()} color={color} variant={"contained"} disabled={loading}>
        {loading && <CircularProgress size={20} style={{color: '#fff'}}/>}
        {!loading && title}
      </Button>
    </>
  )
}

ActionButton.propTypes = {
  className: PropTypes.string
}

export default ActionButton
