import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {FormattedMessage} from "react-intl"
import Alert from "@material-ui/lab/Alert"
import {useParams} from "react-router"
import {globalOptions} from "../../utils/globals"

export const UserCanDo = (permission, endpoint, id = 0) => {
  const {enableAccessGuard} = globalOptions

  const session = useSelector((state) => state.session)
  let locationPath = window.location.pathname
  // let path = endpoint || locationPath
  let path = locationPath

  // if(_.isObject(endpoint)) {
  //   path = endpoint.get
  // }

  if (!endpoint && locationPath.split("/").length > 1) path = locationPath

  let permissionName = `${path.replace('/', '').replace(/\//g, '_')}_${permission}`
  permissionName = permissionName.replace(`_${id}`, '')
  permissionName = permissionName.replace(/\d+/g, '')
  permissionName = permissionName.replace(`__`, '_')
  permissionName = permissionName.replace(`auth_`, '')
  // permissionName = permissionName.replace(`admins`, 'users_users')
  // permissionName = permissionName.replace(`roles`, 'users_roles')
  // permissionName = permissionName.replace(`users_users_roles_add`, 'users_roles_add')

  // console.log(permissionName)

  return !(enableAccessGuard && !session.permissions.includes(permissionName))
}

const AccessGuard = ({permission, children, endpoint, ignorePermissions}) => {
  const {id} = useParams()

  if (UserCanDo(permission, endpoint, id) || ignorePermissions) {
    return <>{children}</>
  }

  return null
}

AccessGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string.isRequired
}

export default AccessGuard
