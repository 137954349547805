import React, { Children, cloneElement } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Slide
} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import palette from "src/theme/palette";
import __ from "../../LanguageHelper";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1.5, 2)
  },
  headerLight: {
    padding: theme.spacing(1.5, 2)
  },
  small: {
    padding: theme.spacing(1, 2)
  },
  headerTitle: {
    color: palette.text.white
  },
  headerTitleLight: {
    color: theme.palette.text.primary
  },
  noPadding: {
    paddingTop: "0!important"
  },
  largeHeader: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3, 2)
  },
  largeHeaderTitle: {
    fontSize: 20,
    fontWeight: 700
  },
  primaryCard: {
    border: "1px solid" + theme.palette.primary.main
  }
}));

const DetailsCard = ({
  className,
  contentClassName,
  children,
  title,
  columns,
  data,
  loading,
  noLoading,
  contentOnly,
  center,
  small,
  dark,
  condition,
  largeHeader,
  action,
  renderHeader,
  primary,
  height
}) => {
  const classes = useStyles();

  if (condition && data) {
    if (!condition(data)) return null;
  }

  let titleClass = classes.headerTitleLight;
  if (dark) titleClass = classes.headerTitle;
  if (largeHeader) titleClass = classes.largeHeaderTitle;

  return (
    <>
      <Grid item md={columns} xs={12} className={small && classes.noPadding}>
        {contentOnly ? (
          <LoadingOverlay active={!noLoading && loading}>
            {Children.map(children, (child, i) => {
              return cloneElement(child, {
                data
              });
            })}
          </LoadingOverlay>
        ) : (
          <Card
            className={clsx(
              classes.root,
              primary && classes.primaryCard,
              className
            )}
          >
            {title && (
              <>
                <CardHeader
                  titleTypographyProps={{ className: titleClass }}
                  title={title}
                  style={{ textAlign: center ? "center" : __("right", "left") }}
                  className={clsx(
                    dark ? classes.header : classes.headerLight,
                    small && classes.small,
                    largeHeader && classes.largeHeader
                  )}
                  action={action || null}
                />
                <Divider />
              </>
            )}

            {renderHeader && renderHeader()}

            <CardContent className={clsx(classes.content, contentClassName)} style={{height}}>
              <LoadingOverlay active={!noLoading && loading}>
                {Children.map(children, (child, i) => {
                  return cloneElement(child, {
                    data
                  });
                })}
              </LoadingOverlay>
            </CardContent>
          </Card>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={`loader`}
        open={!noLoading && loading}
        TransitionComponent={Slide}
        message={__("جاري تحميل البيانات...", "Loading content...")}
      />
    </>
  );
};

DetailsCard.propTypes = {
  className: PropTypes.string
};

export default DetailsCard;
