/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import LocalCarWashOutlinedIcon from '@material-ui/icons/LocalCarWashOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';

import {FormattedMessage} from "react-intl"
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import __ from "../../admin-utils/LanguageHelper"
import StoreIcon from "@material-ui/icons/Store"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import ListIcon from "@material-ui/icons/List"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import AddBoxIcon from "@material-ui/icons/AddBox"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined"
import EventBusyIcon from "@material-ui/icons/EventBusy"
import AssessmentIcon from "@material-ui/icons/Assessment"

export default [
  {
    subheader: __('القائمة الرئيسية ', 'Main Menu'),
    items: [
      {
        title: __('الطلبات', 'Orders'),
        href: '/sorders',
        icon: LocalCarWashOutlinedIcon
      },
      {
        title: __('طلبات التوريد', 'Requests'),
        href: '/srequests',
        icon: BallotOutlinedIcon
      },
      {
        title: __('تقارير الارباح', 'Revenue Reports'),
        href: '/saccount',
        icon: AssessmentIcon
      },
      {
        title: __('الموظفين', 'Employees'),
        href: '/semployees',
        icon: ContactMailOutlinedIcon
      },
    ]
  },{
    subheader: __('تطبيق العميل ', 'Client App'),
    items: [
      {
        title: __('الطلبات', 'Orders'),
        href: '/app/orders',
        icon: PlaylistAddCheckIcon
      },
    ]
  }, {
    subheader: __(' المخازن', 'Stock'),
    items: [
      {
        title: __('مخزن موقع العمل', 'Location Stock'),
        href: '/mall/stock',
        icon: AccountTreeOutlinedIcon
      },
      {
        title: __('طلبات التالف', 'Damage Requests'),
        href: '/mall/damage',
        icon: EventBusyIcon
      },
    ]
  },
]
