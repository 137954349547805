import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {FormattedMessage} from "react-intl"
import {Page, DataTable, Forms} from "src/admin-utils"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({}))

function UserManagementUsers() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef,
    formRef2

  return (
    <Page
      title={__('المستخدمين', 'Users')}
      left={() => (
        <Forms
          onRef={ref => formRef = ref}
          endpoint={{
            get: 'users',
            edit: 'users',
            add: 'admins'
          }}
          params={{
            include: 'roles'
          }}
          title={__('اضافة مستخدم جديد', 'Add New User')}
          editTitle={__('تعديل بيانات المستخدم ', 'Edit User Details')}
          hidden={{
            is_client: 0
          }}
          buttons={['saveExit', 'cancel']}
          fields={[
            {
              title: __('الاسم', 'Name'),
              field: 'name',
              type: 'string',
              rules: ['required',],
            },
            {
              title: __('البريد الالكتروني', 'Email'),
              field: 'email',
              type: 'string',
              rules: ['required',],
            },
            {
              title: __('كلمة المرور', 'Password'),
              field: 'password',
              type: 'password',
              rules: (isEdit) => isEdit ? [] : ['required'],
            },
            {
              title: __('الادوار', 'Roles'),
              field: 'roles',
              type: 'dropdown',
              endpoint: 'roles',
              params: {
                limit: 1000
              },
              val: 'id',
              label: 'description',
              multiple: true,
              rules: ['required',],
            },
            {
              title: __('تحديد الدولة', 'Assign Country'),
              field: 'country_id',
              type: 'dropdown',
              endpoint: 'country',
              params: {
                limit: 1000
              },
              val: 'id',
              label: 'name_en',
            },
            {
              title: __('تحديد موقع العمل', 'Assign Location'),
              field: 'mall_id',
              type: 'dropdown',
              endpoint: 'mall',
              params: {
                limit: 1000
              },
              waitValue: 'country_id',
              val: 'id',
              label: 'name_en',
            },
            {
              title: __('تحديد المنطقة', 'Assign Area'),
              field: 'area_id',
              type: 'dropdown',
              endpoint: 'area',
              params: {
                limit: 1000
              },
              waitValue: 'country_id',
              val: 'id',
              label: 'area',
            },
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >

      <Forms
        hide
        onRef={ref => formRef2 = ref}
        endpoint={'users'}
        title={__('تعديل الكود و الحالة', 'Edit code & status')}
        editTitle={__('تعديل الكود و الحالة', 'Edit code & status')}
        buttons={['saveExit', 'cancel']}
        fields={[
          {
            title: __('الكود', 'Code'),
            field: 'code',
            type: 'string',
            rules: ['required',],
          },
          {
            title: __('الحالة', 'Status'),
            field: 'status',
            type: 'dropdown',
            options: [
              {id: 'active', name: __('مفعل', 'Active')},
              {id: 'inactive', name: __('غير مفعل', 'Inactive')},
            ],
          },
        ]}
        onFinish={() => {
          tableRef.current.onQueryChange()
        }}
      />

      <DataTable
        autoIDColumn={false}
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة المستخدمين', 'Listing Users')}
        endpoint={{
          get: 'admins',
          delete: 'users',
          post: 'users'
        }}
        params={{
          include: 'country,mall,roles'
        }}
        defaultOrder={"code"}
        defaultOrderDirection={"asc"}
        buttons={['edit', 'delete']}
        settings={{
          selection: true,
          deleteSelected: true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        limit={200}
        // pagination={false}
        // sortable={true}
        // sorterLabel={'name'}
        // colors={[
        //   {
        //     color: 'red[100]',
        //     field: 'status',
        //     value: 'inactive',
        //     compare: '='
        //   },
        // ]}
        columns={[
          {
            title: "✎",
            onClick: {
              action: (columnData, rowData) => {
                formRef2.show(rowData.id)
              },
              title: __('تعديل الكود', 'Change Code'),
              type: 'button',
              value: "✎",
            }
          },
          {
            title: __('الكود', 'Code'),
            field: 'code',
            type: 'string'
          },
          {
            title: __('الاسم', 'Name'),
            field: 'name',
            type: 'string'
          },
          {
            title: __('البريد الالكتروني', 'Email'),
            field: 'email',
            type: 'string'
          },
          {
            title: __('الادوار', 'Roles'),
            field: 'roles',
            type: 'chips',
            key: 'description'
          },
          {
            title: __('الدولة', 'Country'),
            field: 'country.name_en',
            type: 'string'
          },
          {
            title: __('موقع العمل', 'Location'),
            field: 'mall.name_en',
            type: 'string'
          },
          {
            title: __('الحالة', 'Status'),
            field: 'status',
            type: 'string',
            options: [
              {id: 'active', name: __('فعال', 'Active')},
              {id: 'inactive', name: __('غير فعال', 'Inactive')},
            ],
            colors: [{
              value: 'active',
              compare: '=',
              color: 'green[300]'
            }, {
              value: 'inactive',
              compare: '=',
              color: 'red[700]'
            }],
          },
        ]}
      />
    </Page>
  )
}

export default UserManagementUsers
