import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {FormattedMessage} from "react-intl"
import {DataTable, DetailsCard, DetailsContainer, Page, Shortcuts} from "src/admin-utils"
import CountWidget from "./Components/CountWidget"
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined"
import EnhancedEncryptionOutlinedIcon from "@material-ui/icons/EnhancedEncryptionOutlined"
import gradients from "../../utils/gradients"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import EventAvailableIcon from "@material-ui/icons/EventAvailable"
import {useSelector} from "react-redux"
import ChartWidget from "./Components/ChartWidget"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({}))

function Dashboard() {
  const classes = useStyles()
  const {user} = useSelector((state) => state.session)

  return (
    <Page
      // title={<FormattedMessage id="listing.hello" defaultMessage="Hello, {name}" values={{name: user.name}}/>}
      title={__(`${user.name}مرحبا,`, `Hello, ${user.name}`)}

      // subTitle={<FormattedMessage id="listing.hello_subtitle" defaultMessage="Here's what's happening"/>}
    >

      <Shortcuts />
    </Page>
  )
}

export default Dashboard
