import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"
import {useSelector} from "react-redux"

const useStyles = makeStyles((theme) => ({}))

function SettingsCarTypes() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef
  const {user: {id: user_id}} = useSelector((state) => state.session)

  return (
    <Page
      title={__('انواع السيارات', 'Car Types')}
      left={() => (
        <Forms
          onRef={ref => formRef = ref}
          title={__('اضافة نوع سيارة جديد', 'Add New Car Type')}
          editTitle={__('تعديل نوع السيارة', 'Edit Car Type')}
          endpoint={'car'}
          params={{
            include: 'countries'
          }}

          fields={[
            {
              title: __('الايقونة', 'Icon'),
              field: 'image',
              type: 'image',
              rules: ['required'],
            },
            [
              {
                title: __('نوع السيارة (بالانجليزية)', 'Car Type (en)'),
                field: 'name_en',
                type: 'string',
                rules: ['required'],
              },
              {
                title: __('نوع السيارة (بالعربية)', 'Car Type (ar)'),
                field: 'name_ar',
                type: 'string',
                rules: ['required'],
              },
            ],
            {
              title: __('الدولة', 'Country'),
              field: 'countries',
              type: 'dropdown',
              endpoint: 'country',
              params: {
                limit: 1000
              },
              multiple: true,
              val: 'id',
              label: 'name_en',
              rules: ['required'],

            },
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >
      <DataTable
        remoteExport
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة انواع السيارات', 'Listing Car Types')}
        endpoint={'car'}
        summary={'car'}
        buttons={['edit', 'delete']}
        params={{
          include: 'countries',
          user_id
        }}
        settings={{
          selection: true,
          deleteSelected:true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        sortable={true}
        sorterLabel={__('name_ar', 'name_en')}
        columns={[
          {
            title: __('الايقونة', 'Icon'),
            field: 'image',
            type: 'image',
            width: 80
          },
          {
            title: __('الاسم', 'Name'),
            field: 'name_en',
            type: 'string',
            summary: 'count',
            sum_after: __('السيارات', 'Cars'),
          },
          // {
          //   title: __('الاسم (بالعربية)', 'Name (ar)'),
          //   field: 'name_ar',
          //   type: 'string',
          // },
          {
            title: __('الدول', 'Countries'),
            field: 'countries',
            type: 'chips',
            key: 'name_en',
            sorting: false
          },
        ]}
        search={[
          {
            title: __('نوع السيارة (بالانجليزية)', 'Car Type (en)'),
            field: 'name_en',
            type: 'string',
            filter: 'like'
          },
          {
            title: __('نوع السيارة (بالعربية)', 'Car Type (ar)'),
            field: 'name_ar',
            type: 'string',
            filter: 'like'
          },
          {
            title: __(' اسم الدولة ', '  Country Name'),
            field: 'include,countries,name_en',
            type: 'string',
            filter: 'like'
          },
        ]}
      />
    </Page>
  )
}

export default SettingsCarTypes
