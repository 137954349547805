/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'
import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'

import Dashboard from 'src/views/Dashboard'
import Orders from 'src/views/Orders'
import OrdersDetails from 'src/views/Orders/OrdersDetails'
import Settings from 'src/views/Settings'
import SettingsCountries from 'src/views/SettingsCountries'
import SettingsMalls from 'src/views/SettingsMalls'
import SettingsCarTypes from 'src/views/SettingsCarTypes'
import SettingsSupplies from 'src/views/SettingsSupplies'
import SettingsServices from 'src/views/SettingsServices'
import UserManagementRoles from 'src/views/UserManagementRoles'
import UserManagementUsers from 'src/views/UserManagementUsers'
import SettingsServiceTypes from 'src/views/SettingsServiceTypes'

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard"/>
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      /* START_ROUTES */{
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/views/Dashboard'))
      },{
        path: '/dashboard/:id',
        exact: true,
        component: lazy(() => import('src/views/Dashboard/DashboardDetails'))
      },{
        path: '/orders',
        exact: true,
        component: lazy(() => import('src/views/Orders'))
      },{
        path: '/orders/:id',
        exact: true,
        component: lazy(() => import('src/views/Orders/OrdersDetails'))
      },{
        path: '/stock/out',
        exact: true,
        component: lazy(() => import('src/views/StockOut'))
      },{
        path: '/stock/out/:id',
        exact: true,
        component: lazy(() => import('src/views/StockOut/StockOutDetails'))
      },{
        path: '/revenue/reports',
        exact: true,
        component: lazy(() => import('src/views/RevenueReports'))
      },{
        path: '/revenue/reports/:id',
        exact: true,
        component: lazy(() => import('src/views/RevenueReports/RevenueReportsDetails'))
      },{
        path: '/expenses',
        exact: true,
        component: lazy(() => import('src/views/Expenses'))
      },{
        path: '/expenses/:id',
        exact: true,
        component: lazy(() => import('src/views/Expenses/ExpensesDetails'))
      },{
        path: '/sorders',
        exact: true,
        component: lazy(() => import('src/views/Sorders'))
      },{
        path: '/sorders/:id',
        exact: true,
        component: lazy(() => import('src/views/Sorders/SordersDetails'))
      },{
        path: '/srequests',
        exact: true,
        component: lazy(() => import('src/views/Srequests'))
      },{
        path: '/srequests/:id',
        exact: true,
        component: lazy(() => import('src/views/Srequests/SrequestsDetails'))
      },{
        path: '/semployees',
        exact: true,
        component: lazy(() => import('src/views/Semployees'))
      },{
        path: '/semployees/:id',
        exact: true,
        component: lazy(() => import('src/views/Semployees/SemployeesDetails'))
      },{
        path: '/saccount',
        exact: true,
        component: lazy(() => import('src/views/Saccount'))
      },{
        path: '/saccount/:id',
        exact: true,
        component: lazy(() => import('src/views/Saccount/SaccountDetails'))
      },{
        path: '/morders',
        exact: true,
        component: lazy(() => import('src/views/Morders'))
      },{
        path: '/morders/:id',
        exact: true,
        component: lazy(() => import('src/views/Morders/MordersDetails'))
      },{
        path: '/maccount',
        exact: true,
        component: lazy(() => import('src/views/Maccount'))
      },{
        path: '/maccount/:id',
        exact: true,
        component: lazy(() => import('src/views/Maccount/MaccountDetails'))
      },{
        path: '/client',
        exact: true,
        component: lazy(() => import('src/views/ClientApp'))
      },{
        path: '/client/clients',
        exact: true,
        component: lazy(() => import('src/views/ClientAppClients'))
      },{
        path: '/client/clients/:id',
        exact: true,
        component: lazy(() => import('src/views/ClientAppClients/ClientAppClientsDetails'))
      },{
        path: '/client/orders',
        exact: true,
        component: lazy(() => import('src/views/ClientAppOrders'))
      },{
        path: '/client/orders/:id',
        exact: true,
        component: lazy(() => import('src/views/ClientAppOrders/ClientAppOrdersDetails'))
      },{
        path: '/client/categories',
        exact: true,
        component: lazy(() => import('src/views/ClientAppCategories'))
      },{
        path: '/client/categories/:id',
        exact: true,
        component: lazy(() => import('src/views/ClientAppCategories/ClientAppCategoriesDetails'))
      },{
        path: '/client/products',
        exact: true,
        component: lazy(() => import('src/views/ClientAppProducts'))
      },{
        path: '/client/products/:id',
        exact: true,
        component: lazy(() => import('src/views/ClientAppProducts/ClientAppProductsDetails'))
      },{
        path: '/client/options',
        exact: true,
        component: lazy(() => import('src/views/ClientAppOptions'))
      },{
        path: '/client/options/:id',
        exact: true,
        component: lazy(() => import('src/views/ClientAppOptions/ClientAppOptionsDetails'))
      },{
        path: '/settings/countries',
        exact: true,
        component: lazy(() => import('src/views/SettingsCountries'))
      },{
        path: '/settings/countries/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsCountries/SettingsCountriesDetails'))
      },{
        path: '/settings/malls',
        exact: true,
        component: lazy(() => import('src/views/SettingsMalls'))
      },{
        path: '/settings/malls/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsMalls/SettingsMallsDetails'))
      },{
        path: '/settings/areas',
        exact: true,
        component: lazy(() => import('src/views/SettingsAreas'))
      },{
        path: '/settings/areas/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsAreas/SettingsAreasDetails'))
      },{
        path: '/settings/car/types',
        exact: true,
        component: lazy(() => import('src/views/SettingsCarTypes'))
      },{
        path: '/settings/car/types/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsCarTypes/SettingsCarTypesDetails'))
      },{
        path: '/settings/supplies',
        exact: true,
        component: lazy(() => import('src/views/SettingsSupplies'))
      },{
        path: '/settings/supplies/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsSupplies/SettingsSuppliesDetails'))
      },{
        path: '/settings/services',
        exact: true,
        component: lazy(() => import('src/views/SettingsServices'))
      },{
        path: '/settings/services/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsServices/SettingsServicesDetails'))
      },{
        path: '/settings/service/types',
        exact: true,
        component: lazy(() => import('src/views/SettingsServiceTypes'))
      },{
        path: '/settings/service/types/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsServiceTypes/SettingsServiceTypesDetails'))
      },{
        path: '/settings/covers',
        exact: true,
        component: lazy(() => import('src/views/SettingsCovers'))
      },{
        path: '/settings/covers/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsCovers/SettingsCoversDetails'))
      },{
        path: '/area/supervisor',
        exact: true,
        component: lazy(() => import('src/views/AreaSupervisor'))
      },{
        path: '/area/supervisor/:id',
        exact: true,
        component: lazy(() => import('src/views/AreaSupervisor/AreaSupervisorDetails'))
      },{
        path: '/settings/app',
        exact: true,
        component: lazy(() => import('src/views/SettingsApp'))
      },{
        path: '/settings/app/:id',
        exact: true,
        component: lazy(() => import('src/views/SettingsApp/SettingsAppDetails'))
      },{
        path: '/warehouse',
        exact: true,
        component: lazy(() => import('src/views/Warehouse'))
      },{
        path: '/warehouse/:id',
        exact: true,
        component: lazy(() => import('src/views/Warehouse/WarehouseDetails'))
      },{
        path: '/supply/invoice',
        exact: true,
        component: lazy(() => import('src/views/SupplyInvoice'))
      },{
        path: '/supply/invoice/:id',
        exact: true,
        component: lazy(() => import('src/views/SupplyInvoice/SupplyInvoiceDetails'))
      },{
        path: '/mall/supply',
        exact: true,
        component: lazy(() => import('src/views/MallSupply'))
      },{
        path: '/mall/supply/:id',
        exact: true,
        component: lazy(() => import('src/views/MallSupply/MallSupplyDetails'))
      },{
        path: '/stock/used',
        exact: true,
        component: lazy(() => import('src/views/StockUsed'))
      },{
        path: '/stock/used/:id',
        exact: true,
        component: lazy(() => import('src/views/StockUsed/StockUsedDetails'))
      },{
        path: '/stock/damage',
        exact: true,
        component: lazy(() => import('src/views/StockDamage'))
      },{
        path: '/stock/damage/:id',
        exact: true,
        component: lazy(() => import('src/views/StockDamage/StockDamageDetails'))
      },{
        path: '/app/orders',
        exact: true,
        component: lazy(() => import('src/views/AppOrders'))
      },{
        path: '/app/orders/:id',
        exact: true,
        component: lazy(() => import('src/views/AppOrders/AppOrdersDetails'))
      },{
        path: '/mall/stock',
        exact: true,
        component: lazy(() => import('src/views/MallStock'))
      },{
        path: '/mall/stock/:id',
        exact: true,
        component: lazy(() => import('src/views/MallStock/MallStockDetails'))
      },{
        path: '/mall/damaged',
        exact: true,
        component: lazy(() => import('src/views/MallDamaged'))
      },{
        path: '/mall/damaged/:id',
        exact: true,
        component: lazy(() => import('src/views/MallDamaged/MallDamagedDetails'))
      },{
        path: '/hand/over',
        exact: true,
        component: lazy(() => import('src/views/HandOver'))
      },{
        path: '/hand/over/:id',
        exact: true,
        component: lazy(() => import('src/views/HandOver/HandOverDetails'))
      },{
        path: '/user/management',
        exact: true,
        component: lazy(() => import('src/views/UserManagement'))
      },{
        path: '/user/management/permissions',
        exact: true,
        component: lazy(() => import('src/views/UserManagementPermissions'))
      },{
        path: '/user/management/permissions/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementPermissions/UserManagementPermissionsDetails'))
      },{
        path: '/user/management/roles',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles'))
      },{
        path: '/user/management/roles/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles/UserManagementRolesDetails'))
      },{
        path: '/user/management/users',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers'))
      },{
        path: '/user/management/users/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers/UserManagementUsersDetails'))
      },
      /* END_ROUTES */

















      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  }
]
