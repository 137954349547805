import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms, Space} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({}))

function SettingsSupplies() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef
  let formRef2

  return (
    <Page
      title={__('التوريدات', 'Supplies')}
      left={() => (
        <>
          <Forms
            onRef={ref => formRef = ref}
            title={__('اضافة منتج جديد', 'Add New Supply')}
            editTitle={__('تعديل المنتج', 'Edit Supply')}
            endpoint={'supply'}
            params={{
              include: 'countries,'
            }}

            fields={[
            {
                title: __('يمكنك رفع صورة المنتج', 'Upload supply Image'),
                field: 'image',
                type: 'image',
                rules: ['required'],
              },
              [
                {
                  title: __('اسم المنتج (بالانجليزية)', 'Supply Name (en)'),

                  field: 'name_en',
                  type: 'string',
                  rules: ['required',],
                },
                {
                  title: __(' اسم المنتج (بالعربية) ', '  Supply Name (ar)'),

                  field: 'name_ar',
                  type: 'string',
                  rules: ['required',],
                },
              ],
              {
                title: __('الباركود', 'Barcode'),
                field: 'barcode',
                type: 'string',
              },
              {
                title: __(' اسم الدولة ', '  Country Name'),

                field: 'countries',
                type: 'dropdown',
                endpoint: 'country',
                params: {
                  limit: 1000
                },
                multiple: true,
                val: 'id',
                label: 'name_en',
                rules: ['required'],
              },
            ]}
            onFinish={() => {
              tableRef.current.onQueryChange()
            }}
          />

          {/*<Space width={10} />*/}

          {/*<Forms*/}
          {/*  onRef={ref => formRef2 = ref}*/}
          {/*  title={__('فاتورة توريد', 'Supplying invoice')}*/}
          {/*  endpoint={'stock_in'}*/}
          {/*  params={{*/}
          {/*    include: 'countries'*/}
          {/*  }}*/}
          {/*  fields={[*/}
          {/*    {*/}
          {/*      title: __('الصنف', 'Item'),*/}
          {/*      field: 'supply_id',*/}
          {/*      type: 'dropdown',*/}
          {/*      endpoint: 'supply',*/}
          {/*      params: {*/}
          {/*        limit: 1000*/}
          {/*      },*/}
          {/*      val: 'id',*/}
          {/*      label: 'name_en',*/}
          {/*      rules: ['required',],*/}
          {/*    },*/}
          {/*    {*/}
          {/*      title: __('الكمية', 'Quantity'),*/}
          {/*      field: 'quantity',*/}
          {/*      type: 'number',*/}
          {/*      rules: ['required',],*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*  onFinish={() => {*/}
          {/*    tableRef.current.onQueryChange()*/}
          {/*  }}*/}
          {/*/>*/}
        </>
      )}
    >

      <DataTable
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة المنتجات', 'Listing Supplies')}
        endpoint={'supply'}
        summary={'supply'}
        buttons={['edit', 'delete']}
        params={{
          include: 'countries'
        }}
        limit={200}
        // pagination={false}
        settings={{
          selection: true,
          deleteSelected:true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        columns={[
          {
            title: __('الصورة', 'Image'),
            field: 'image',
            type: 'image',
            width: 80,
            sorting:false,
          },
          {
            title: __('الاسم', 'Name'),
            field: 'name_en',
            type: 'string',
            summary: 'count',
            sum_after: __('المنتجات', 'Supplies'),
          },
          {
            title: __('الباركود', 'Barcode'),
            field: 'barcode',
            type: 'string',
          },
          {
            title: __('الدولة', 'Country'),
            field: 'countries',
            type: 'chips',
            key: 'name_en',
            sorting: false
          },
        ]}
        search={[
          {
            title: __('اسم المنتج (بالانجليزية)', 'Supply Name (en)'),
            field: 'name_en',
            type: 'string',
            filter: 'like'
          },
          {
            title: __(' اسم المنتج (بالعربية) ', '  Supply Name (ar)'),
            field: 'name_ar',
            type: 'string',
          },
          {
            title: __('الباركود', 'Barcode'),
            field: 'barcode',
            type: 'string',
          },
          {
            title: __(' اسم الدولة ', '  Country Name'),
            field: 'include,countries,name_en',
            type: 'string',
            filter: 'like'
          },
        ]}
      />
    </Page>
  )
}

export default SettingsSupplies
