import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles';
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router"
import __ from "../../admin-utils/LanguageHelper";

const useStyles = makeStyles((theme) => ({}));

function SettingsServices() {
  const classes = useStyles();
  const tableRef = useRef()
  let formRef
  let formRef2
  let {id} = useParams()
  const [updater, setUpdater] = useState(false)

  return (
    <Page
      title={__('الخدمات', 'Services')}
      left={() => (
        <Forms
          onRef={ref => formRef2 = ref}
          title={__('اضافة خدمة جديدة', 'Add New service')}
          editTitle={__('تعديل الخدمة', 'Edit service')}
          endpoint={'service'}
          params={{
            include: 'car,country,service_type'
          }}
          fields={[
            [
              {
                title: __('الدولة', 'Country'),
                field: 'country_id',
                type: 'dropdown',
                endpoint: 'country',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                rules: ['required'],
              },
              {
                title: __('نوع السيارة', 'Car Type'),
                field: 'car_id',
                type: 'dropdown',
                endpoint: 'car',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                rules: ['required'],
              },
            ],
            [
              {
                title: __('نوع الخدمة', 'Service Type'),
                field: 'service_type_id',
                type: 'dropdown',
                endpoint: 'service_type',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                rules: ['required'],
              },
              {
                title: __('السعر', 'Price'),
                field: 'price',
                type: 'numeric',
                rules: ['required'],
              },
            ],
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >

      <DataTable
        ref={tableRef}
        editAction={(id) => {
          formRef2.show(id)
        }}
        title={__('قائمة الخدمات', 'Listing services')}
        endpoint={'service'}
        summary={'service'}
        // buttons={["edit", "delete",]}
        params={{
          include: 'car,country,service_type'
        }}
        limit={30}
        // pagination={false}
        settings={{
          selection: true,
          deleteSelected:true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        columns={[
          {
            title: __('السيارة', 'Car'),
            field: 'car.name_en',
            type: 'string',
            sorting: false
          },
          {
            title: __('الخدمة', 'Service'),
            field: 'service_type.name_en',
            type: 'string',
            summary: 'count',
            sum_after: __('الخدمات', 'Services'),
          },
          {
            title: __('السعر', 'Price'),
            field: 'price',
            type: 'money',
          },
          {
            title: __('الدولة', 'Country'),
            field: 'country.name_en',
            type: 'string',
            sorting: false
          },
          {
            title: __('الاغطية المجانية', 'Free Covers'),
            onClick: {
              link: ['settings/services/{id}', 'id'],
              title: __('الاغطية المجانية', 'Free Covers'),
              type: 'button',
              value: __('الاغطية المجانية', 'Free Covers'),
            },
            sorting:false,
          },
        ]}
        search={[
          {
            title: __('الخدمة', 'Service'),
            field: 'service_type_id',
            type: 'dropdown',
            endpoint: 'service_type',
            params: {
              limit: 1000
            },
            multiple: true,
            val: 'id',
            label: 'name_en',
          },
          {
            title: __('السيارة', 'Car'),
            field: 'car_id',
            type: 'dropdown',
            endpoint: 'car',
            params: {
              limit: 1000
            },
            multiple: true,
            val: 'id',
            label: 'name_en',
          },
          {
            title: __('اسم الدولة', 'Country Name'),
            field: 'country_id',
            type: 'dropdown',
            endpoint: 'country',
            params: {
              limit: 1000
            },
            multiple: true,
            val: 'id',
            label: 'name_en',
          },
          {
            title: __('السعر', 'Price'),
            field: 'price',
            type: 'numeric',
            filter: 'range'
          },
        ]}
      />
    </Page>
  );
}

export default SettingsServices;
