/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useHistory} from 'react-router'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import {makeStyles} from '@material-ui/styles'
import {AppBar, Button, colors, Hidden, IconButton, Input, Toolbar} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import axios from 'src/utils/axios'
import Typography from "@material-ui/core/Typography"
import {FormattedMessage} from "react-intl"
import AuthActions from "../../redux/reducers/AuthRedux"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: '#fff',
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'rgba(0,0,0, 0.1)',
    },
    borderRadius: 4,
    flexBasis: 400,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: '#inherit',
    [theme.breakpoints.up('lg')]: {
      color: '#646464',
    },
  },
  searchInput: {
    fontWeight: 'normal',
    flexGrow: 1,
    color: 'inherit',
    [theme.breakpoints.up('lg')]: {
      color: '#333',
    },
    fontSize: 14,
    '& input::placeholder': {
      opacity: 1,
      color: '#dcdcdc',
      [theme.breakpoints.up('lg')]: {
        color: '#646464',
      },
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: '#363637'
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    color: theme.palette.common.black,
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  logo: {
    flex: 1,
    color: theme.palette.primary.main
  }
}))

function TopBar({onOpenNavBarMobile, className, logo, searchPlaceholder, logoutTitle, ...rest}) {
  const classes = useStyles()
  const history = useHistory()
  const searchRef = useRef(null)
  const dispatch = useDispatch()
  const notificationsRef = useRef(null)
  const [openSearchPopover, setOpenSearchPopover] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [notifications, setNotifications] = useState([])
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openChatBar, setOpenChatBar] = useState(false)
  const [pricingModalOpen, setPricingModalOpen] = useState(false)

  const handleLogout = () => {
    history.push('/auth/login')
    dispatch(AuthActions.authLogout())
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true)
      }
    } else {
      setOpenSearchPopover(false)
    }
  }

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false)
  }

  useEffect(() => {
    let mounted = true

    const fetchNotifications = () => {
      axios.get('/api/account/notifications').then((response) => {
        if (mounted) {
          setNotifications(response.data.notifications)
        }
      })
    }

    fetchNotifications()

    return () => {
      mounted = false
    }
  }, [])

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={onOpenNavBarMobile}
        >
          <MenuIcon/>
        </IconButton>

        <div className={classes.logo}>
          PARKNWASH
        </div>

        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon}/>
          {logoutTitle}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
}

export default TopBar
