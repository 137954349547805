import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux/src/hooks/useSelector"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({

}));

function Settings() {
  const classes = useStyles()
  // let formRef
  // const {token} = useSelector((state) => state.session)
  // const [settings, setSettings] = useState({})
  // const [loading, setLoading] = useState(true)
  //
  // useEffect(() => {
  //   getSettings().finally()
  // }, [])
  //
  // async function getSettings() {
  //   let request = await getData('settings', {}, token)
  //   let settings = request.data
  //
  //   let settingsValues = {}
  //   settings.map(setting => {
  //     settingsValues[setting.key] = setting.value
  //   })
  //
  //   setSettings(settingsValues)
  //   setLoading(false)
  // }

    return (
        <Page
          title={__('اعدادات التطبيق', 'App Settings')}
        >
          {/*<LoadingOverlay active={loading} spinner>*/}
          {/*  <Forms*/}
          {/*    type={"inline"}*/}
          {/*    onRef={ref => formRef = ref}*/}
          {/*    endpoint={'settings'}*/}
          {/*    title={__('تعديل الإعدادات', 'Modify Settings')}*/}
          {/*    defaultValues={settings}*/}
          {/*    fields={[*/}
          {/*      [*/}
          {/*        {*/}
          {/*          title: __('عن التطبيق (بالانجليزية)', 'About Us (en)'),*/}
          {/*          field: 'about_en',*/}
          {/*          type: 'editor',*/}
          {/*        },*/}
          {/*        {*/}
          {/*          title: __('عن التطبيق (بالعربية)', 'About Us (ar)'),*/}
          {/*          field: 'about_ar',*/}
          {/*          type: 'editor',*/}
          {/*        },*/}
          {/*      ],*/}
          {/*      [*/}
          {/*        {*/}
          {/*          title: __('البريد الالكتروني', 'Email'),*/}
          {/*          field: 'email',*/}
          {/*          type: 'string',*/}
          {/*          rules: ['email'],*/}
          {/*        },*/}
          {/*        {*/}
          {/*          title: __('رقم الهاتف', 'Phone'),*/}
          {/*          field: 'phone',*/}
          {/*          type: 'phone',*/}
          {/*        },*/}
          {/*      ],*/}
          {/*    ]}*/}
          {/*  />*/}
          {/*</LoadingOverlay>*/}

        </Page>
    );
}

export default Settings;
