/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import ListAltIcon from '@material-ui/icons/ListAlt'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import AlarmOnIcon from '@material-ui/icons/AlarmOn'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import WidgetsIcon from '@material-ui/icons/Widgets'
import AppsIcon from '@material-ui/icons/Apps'
import BallotIcon from '@material-ui/icons/Ballot'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import RateReviewIcon from '@material-ui/icons/RateReview'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import StarsIcon from '@material-ui/icons/Stars'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import EmailIcon from '@material-ui/icons/Email'
import FilterTiltShiftIcon from '@material-ui/icons/FilterTiltShift'
import UpdateIcon from '@material-ui/icons/Update'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import GridOnIcon from '@material-ui/icons/GridOn'
import FlagIcon from '@material-ui/icons/Flag'
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark'
import InputIcon from '@material-ui/icons/Input'


import {FormattedMessage} from "react-intl"
import __ from "src/admin-utils/LanguageHelper"
import LocalCarWashOutlinedIcon from "@material-ui/icons/LocalCarWashOutlined"
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined"
import CommuteOutlinedIcon from "@material-ui/icons/CommuteOutlined"
import ScatterPlotOutlinedIcon from "@material-ui/icons/ScatterPlotOutlined"
import ListIcon from "@material-ui/icons/List"
import OutlinedFlagIcon from "@material-ui/icons/OutlinedFlag"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined"
import EventBusyIcon from "@material-ui/icons/EventBusy"
import AssessmentIcon from "@material-ui/icons/Assessment"
import MoneyIcon from "@material-ui/icons/Money"
import StoreIcon from "@material-ui/icons/Store"
import SwapVerticalCircleOutlinedIcon from "@material-ui/icons/SwapVerticalCircleOutlined"

export default [
  {
    subheader: __('تطبيق الموظف', 'Employee App'),
    items: [
      {
        title: __('الطلبات', 'Orders'),
        href: '/orders',
        icon: LocalCarWashOutlinedIcon,
        color: 'blue'
      },
      // {
      //   title: __('تسليم يومية', 'Hand Over'),
      //   href: '/hand/over',
      //   icon: SwapVerticalCircleOutlinedIcon
      // },
      {
        title: __('التوريدات', 'Supplies'),
        href: '/settings/supplies',
        icon: ScatterPlotOutlinedIcon,
        color: 'blue'
      },
      {
        title: __('تقارير الارباح', 'Revenue Reports'),
        href: '/revenue/reports',
        icon: AssessmentIcon,
        color: 'blue'
      },
      {
        title: __('المصاريف', 'Expenses'),
        href: '/expenses',
        icon: MoneyIcon,
        color: 'blue'
      },
    ]
  }, {
    subheader: __('تطبيق العميل ', 'Client App'),
    items: [
      {
        title: __('العملاء', 'Clients'),
        href: '/client/clients',
        icon: AssignmentIndIcon,
        color: 'indigo'
      },
      {
        title: __('الطلبات', 'Orders'),
        href: '/client/orders',
        icon: PlaylistAddCheckIcon,
        color: 'indigo'
      },
      {
        title: __('الأقسام', 'Categories'),
        href: '/client/categories',
        icon: ListIcon,
        color: 'indigo'
      },
      {
        title: __('المنتجات', 'Products'),
        href: '/client/products',
        icon: AddShoppingCartIcon,
        color: 'indigo'
      },
    ]
  },{
    subheader: __(' الاعدادات', 'Settings'),
    items: [
      {
        title: __('البلاد', 'Countries'),
        href: '/settings/countries',
        icon: OutlinedFlagIcon,
        color: 'deepPurple'
      },
      {
        title: __('مواقع العمل', 'Locations'),
        href: '/settings/malls',
        icon: StoreMallDirectoryOutlinedIcon,
        color: 'deepPurple'
      },
      {
        title: __('الخدمات', 'Services'),
        href: '/settings/services',
        icon: CommuteOutlinedIcon,
        color: 'deepPurple'
      },
      {
        title: __('التوريدات', 'Supplies'),
        href: '/settings/supplies',
        icon: ScatterPlotOutlinedIcon,
        color: 'deepPurple'
      },
    ]
  },{
    subheader: __(' المخازن', 'Stock'),
    items: [
      {
        title: __('المستودع الرئيسي', 'Warehouse'),
        href: '/warehouse',
        icon: StoreIcon,
        color: 'teal'
      },
      {
        title: __('فواتير التوريد', 'Stock Invoices'),
        href: '/supply/invoice',
        icon: DescriptionOutlinedIcon,
        color: 'teal'
      },
      {
        title: __('مخازن مواقع العمل', 'Stock Locations'),
        href: '/mall/stock',
        icon: AccountTreeOutlinedIcon,
        color: 'teal'
      },
      {
        title: __('التوريد لمواقع العمل', 'Locations Supply'),
        href: '/mall/supply',
        icon: DescriptionOutlinedIcon,
        color: 'teal'
      },
      {
        title: __('الخارج من مخازن مواقع العمل', 'Locations Stocks Out'),
        href: '/stock/out',
        icon: BallotOutlinedIcon,
        color: 'teal'
      },
      {
        title: __('التالف', 'Damaged'),
        href: '/mall/damaged',
        icon: EventBusyIcon,
        color: 'teal'
      },
    ]
  }, {
    subheader: __(' الادارة', 'User Management'),
    items: [
      // {
      //   title: __('الادوار و الصلاحيات', 'Roles'),
      //   href: '/user/management/roles',
      //   icon: ContactsOutlinedIcon,
      //   color: 'cyan'
      // },
      {
        title: __('المستخدمين', 'Users'),
        href: '/user/management/users',
        icon: PersonAddOutlinedIcon,
        color: 'cyan'
      }
    ]
  }, {
    subheader: __(' تسجيل الخروج', 'Log Out'),
    items: [
      {
        title: __('تسجيل الخروج', 'Log Out'),
        href: '/auth/login',
        icon: InputIcon,
        color: 'red',
      },
    ]
  },
]
