import React, {useState} from 'react'
import {Router} from 'react-router-dom'
import {renderRoutes} from 'react-router-config'
import {createBrowserHistory} from 'history'
import MomentUtils from '@date-io/moment'
import {Provider as StoreProvider} from 'react-redux'
import {ThemeProvider} from '@material-ui/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {theme, themeWithRtl} from './theme'
import configureStore from './redux/configureStore'
import routes from './routes'
import ScrollReset from './components/ScrollReset'
import StylesProvider from './components/StylesProvider'
import DirectionToggle from './components/DirectionToggle'
import {IntlProvider} from 'react-intl'
import * as messages_ar from './locales/ar'
import * as messages_en from './locales/en'
import {PersistGate} from 'redux-persist/integration/react'

import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './mock'
import './assets/scss/main.scss'
import AuthGuard from "./components/AuthGuard"
import DetailsPanel from "./admin-utils/Components/Page/DetailsPanel"
import {getQueryParams} from "./utils/globals"

const history = createBrowserHistory()
const store = configureStore()

function App() {
  let savedDirection = localStorage.getItem("direction") || 'en'
  const queryParams = getQueryParams()

  if (queryParams.lang) {
    savedDirection = queryParams.lang === "ar" ? "rtl" : "ltr"
    localStorage.setItem("direction", savedDirection)

    window.location = `?autologin=true&email=${queryParams.email}&password=${queryParams.password}`
  }

  const handleDirectionToggle = () => {
    const direction = savedDirection === 'ltr' ? 'rtl' : 'ltr'
    localStorage.setItem('direction', direction)

    setTimeout(() => {
      window.location.reload()
    })
  }

  const messages = {
    'en': messages_en,
    'ar': messages_ar,
  }

  const language = savedDirection === 'rtl' ? 'ar' : 'en'
  const OtherLanguage = savedDirection === 'rtl' ? 'en' : 'ar'

  return (
    <StoreProvider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <IntlProvider locale={language} messages={messages[language].default}>
          <ThemeProvider theme={savedDirection === 'rtl' ? themeWithRtl : theme}>
            <StylesProvider direction={savedDirection}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={history}>
                  <ScrollReset/>
                  <DirectionToggle
                    direction={OtherLanguage}
                    onToggle={handleDirectionToggle}
                  />
                  <AuthGuard permission={'access-dashboard'}>
                    {renderRoutes(routes)}

                    <DetailsPanel
                      ref={c => {
                        if (c) DetailsPanel.detailsPanelInstance = c
                      }}
                    />
                  </AuthGuard>
                </Router>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </IntlProvider>
      </PersistGate>
    </StoreProvider>
  )
}

export default App
