import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Autocomplete, Select, TextField} from 'mui-rff'
import {getData} from "src/admin-utils/API"
import {useSelector} from "react-redux"
import _ from 'lodash'
import CircularProgress from "@material-ui/core/CircularProgress"
import FormLabel from "../../FormLabel"
import {OnChange} from 'react-final-form-listeners'
import * as uuid from "uuid"
import MenuItem from "@material-ui/core/MenuItem"
import './FormPicker.scss'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from "@material-ui/core/Checkbox"
import __ from "../../../../LanguageHelper"
import {Button} from "@material-ui/core"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  loader: {
    color: '#c1c1c1'
  },
  option: {
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      borderColor: 'transparent',
    },
  },
}))

const itemLabel = function (option, rest) {
  let labelOut = ''
  const {label, keys, separator = '-'} = rest

  if (label) {
    let newLabel = label

    if(label.includes('_en')) {
      let cleanName = label.replace('_en', '').replace('_ar', '')
      newLabel = __(`${cleanName}_ar`, `${cleanName}_en`)
    }

    labelOut = _.get(option, newLabel)
  }else{
    labelOut = option['name_en'] ? option['name_en'] : option['name']
  }

  if (keys) {
    let labels = []

    keys.map(key => {
      if (_.isObject(key)) {
        labels.push(`${key.prepend} ${_.get(option, key.key)} ${key.append}`)
      } else {
        labels.push(_.get(option, key))
      }
    })

    if (labels.length > 0) {
      labelOut = labels.join(` ${separator} `)
    }
  }

  return labelOut
}

const FormPicker = ({field, title, mutators, form, type, onChange, onLoad, value, values, search, isFilter, waitValue, required, prepend = [], append = [], ...rest}) => {
  const classes = useStyles()
  let {endpoint, params, options = [], label = 'name', val = 'id', multiple, selectAll} = rest

  const {token} = useSelector((state) => state.session)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(options)
  const [inputKey, setInputKey] = useState(uuid())

  useEffect(() => {
    if(options) {
      onLoad(options)
    }

    if (waitValue && values && values[waitValue]) {
      getRemoteDate({
        key: waitValue,
        value: values[waitValue]
      }).finally()

      return
    }

    if (data.length > 0 || waitValue) return

    if (endpoint) {
      getRemoteDate().finally()
    }

  }, [endpoint])

  const getRemoteDate = async (search) => {
    setLoading(true)

    if (search) {
      params.search = `${search.key}:${search.value}`

      if(search.value === 'all') {
        delete params.search
      }
    }

    const request = await getData(endpoint, params, token)
    const data = _.get(request, 'data')

    setData([...prepend, ...data, ...append])
    setLoading(false)

    onLoad(data)

    setInputKey(uuid())
  }

  return (
    <>
      <FormLabel title={title} isFilter={isFilter}/>

      {waitValue && <OnChange name={waitValue}>
        {(v, previous) => {
          if (v) {
            getRemoteDate({
              key: waitValue,
              value: v
            }).finally()
          } else {
            setData([])
            mutators.clear(field)
            setInputKey(uuid())
          }
        }}
      </OnChange>}

      <OnChange name={field}>
        {(v) => {
          if (onChange) {
            let newData = onChange(data.find(i => i.id === v), values)

            if (_.isArray(newData)) {
              newData.map(d => {
                mutators.setValue(d.field, d.value)
              })
            } else {
              mutators.setValue(newData.field, newData.value)
            }
          }
        }}
      </OnChange>

      {type === "select" && <div className={classes.root}>
        <Select
          id={field}
          name={field}
          label={!isFilter && title}
          className={classes.select}
          variant="outlined"
          size={'small'}
          margin="none"
          disabled={loading}
        >
          {data.map((option) => (
            <MenuItem value={option[val]}>{itemLabel(option, rest)}</MenuItem>
          ))}
        </Select>
      </div>}

      {type === "dropdown" && <Autocomplete
        classes={{
          option: classes.option,
        }}
        required={required}
        margin="none"
        key={inputKey}
        disabled={loading}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        name={field}
        options={data}
        getOptionValue={option => option[val]}
        getOptionLabel={option => itemLabel(option, rest)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {multiple && <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />}
            {itemLabel(option, rest)}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            margin="none"
            name={`${field}_search_text`}
            label={!isFilter && title}
            placeholder={isFilter && title}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" className={classes.loader} size={20}/> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        label={!isFilter && title}
        placeholder={isFilter && title}
        variant="outlined"
        size={'small'}
      />}

      {(selectAll && !loading) && <Button
        style={{marginTop: 10}}
        size={'small'}
        variant="outlined"
        color="secondary"
        onClick={() => mutators.setValue(field, data.map(item => item[val]))}
      >
        {__('تحديد الكل', 'Select All')}
      </Button>}
    </>
  )
}

FormPicker.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormPicker
