import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms, Space} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import Chip from "@material-ui/core/Chip"
import __ from "../../admin-utils/LanguageHelper"
import Modules from "../../layouts/Dashboard/navConfigPure"
import ActionButton from "../../admin-utils/Components/Tools/ActionButton"
import swal from "sweetalert"
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  detailsContainer: {
    padding: theme.spacing(1)
  }
}))

function UserManagementRoles() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef
  // const [state, setState] = useState(null);

  const flatModules = () => {
    let flat = [],
      mainModules = Modules[0].items

    mainModules.map(main => {
      if (main.items) {
        main.items.map(sub => flat.push(sub))
      } else {
        flat.push(main)
      }
    })

    return flat
  }

  const [modules] = useState(flatModules())
  const [permissionsNames, setPermissionsNames] = useState([])
  const [permissionsDescriptions, setPermissionsDescriptions] = useState([])

  const groups = {
    browse: __('تصفح', 'Browse'),
    read: __('عرض', 'Read'),
    add: __('اضافة', 'Add'),
    edit: __('تعديل', 'Edit'),
    delete: __('حذف', 'Delete'),
  }

  const formatPermission = (permission, key) => {
    permission = permission.replace('/', '')
    permission = permission.replace(/\//g, '_')
    return `${permission}_${key}`
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const permissionDescription = (permission, key) => {
    return `${capitalize(key)} ${permission}`
  }

  useEffect(() => {
    let permissionsNames = [],
      permissionsDescriptions = [],
      keys = Object.keys(groups)

    modules.map((module, index) => {
      keys.map(key => {
        let formattedPermission = formatPermission(module.href, key)
        permissionsNames.push(formattedPermission)
        permissionsDescriptions.push(permissionDescription(module.title, key))
      })
    })

    setPermissionsNames(permissionsNames)
    setPermissionsDescriptions(permissionsDescriptions)
  }, [])

  return (
    <Page
      title={__('الادوار', 'Roles')}
      left={() => (
        <>
          <ActionButton
            color={'secondary'}
            show={process.env.NODE_ENV !== "production"}
            title={__('مزامنة الصلاحيات', 'Sync Permissions')}
            endpoint={'bulk/permissions'}
            params={{
              names: permissionsNames,
              descriptions: permissionsDescriptions
            }}
            onFinish={() => {
              swal({
                title: __('عملية ناجحة', 'Success'),
                text: __('تمت مزامنة الصلاحيات بنجاح, قم باعادة حفظ الادوار', 'Permissions synced successfully, you have to re-save roles'),
                icon: "success",
                button: __('حسنا', 'OK'),
              }).finally()
            }}
          />

          <Space width={20}/>

          <Forms
            onRef={ref => formRef = ref}
            endpoint={'roles'}
            title={__('اضافة دور جديد', 'Add New Role')}
            editTitle={__('تعديل الدور', 'Edit Role')}
            fields={[
              {
                title: __('عنوان', 'Title'),
                field: 'description',
                type: 'string',
                rules: ['required'],
              },
              {
                title: __('اسم', 'Name'),
                field: 'name',
                type: 'string',
                rules: ['required'],
              },
            ]}
            hidden={{
              display_name: ':description',
              guard_name: 'web',
              level: 999,
            }}
            onFinish={() => {
              tableRef.current.onQueryChange()
            }}
          />
        </>
      )}
    >
      <DataTable
        ref={tableRef}
        simple
        withActions
        protect={process.env.NODE_ENV !== "production" ? [] : [1]}
        title={__('الادوار المتاحة', 'Available Roles')}
        endpoint={'roles'}
        buttons={['show']}
        columns={[
          {
            title: __('عنوان', 'Title'),
            field: 'display_name',
            type: 'string'
          },
          {
            title: __('اسم', 'Name'),
            field: 'name',
            type: 'string'
          },
        ]}
        editAction={(id) => {
          formRef.show(id)
        }}
        details={(rowData) => {
          return (
            <div className={classes.detailsContainer}>
              {rowData.rowData.permissions.map((permission) => (
                <Chip
                  label={permission.description}
                  color={'primary'}
                  variant={'outlined'}
                  className={classes.chip}
                  size="small"
                />
              ))}
            </div>
          )
        }}
      />
    </Page>
  )
}

export default UserManagementRoles
