import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks"
import IconButton from "@material-ui/core/IconButton"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import {Link} from "react-router-dom"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import VisibilityIcon from "@material-ui/icons/Visibility"
import Typography from "@material-ui/core/Typography"
import {FormattedMessage} from "react-intl"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import React, {useState} from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import LoadingOverlay from "react-loading-overlay"
import {remove_record} from "../../../API"
import {useSelector} from "react-redux"
import AccessGuard from "../../AccessGuard"
import {Grid, Hidden, LinearProgress, Slide} from "@material-ui/core"
import __ from "../../../LanguageHelper"
import Snackbar from "@material-ui/core/Snackbar"
import _ from "lodash"
import {AiOutlineEye, BiDetail, BiEdit, BiPrinter, BiTrash, FiEdit, VscOpenPreview} from "react-icons/all"
import {makeStyles} from "@material-ui/styles"

export const DeleteConfirm = ({open, handleClose, tableRef, endpoint, id, onDelete}) => {
  const {token} = useSelector((state) => state.session)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const isMulti = _.isArray(id)

  const deleteRecord = async () => {
    setLoading(true)

    let ids = isMulti ? id : [id]
    let progressValue = 100 / (ids.length - 1),
      currentProgress = progress

    for (const id of ids) {
      await remove_record(endpoint, id, token)

      currentProgress += progressValue
      setProgress(currentProgress)
    }

    tableRef.current.onQueryChange()

    onDelete && onDelete()

    setLoading(false)
    setProgress(0)
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isMulti && <LinearProgress variant="determinate" value={progress} />}
        <LoadingOverlay
          active={loading}
        >
          <DialogTitle id="alert-dialog-title">{__('تأكيد الحذف', 'Confirm delete')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {__(`هل أنت متأكد من حذف ${isMulti ? 'البيانات' : 'البيان'}؟`, `Are you sure you want to delete ${isMulti ? 'selected records': 'this record'}?`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              {__('إلغاء', 'Cancel')}
            </Button>
            <Button onClick={() => {
              deleteRecord().finally()
            }} color="primary" autoFocus>
              {__('تأكيد الحذف', 'Confirm Delete')}
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key={`loader`}
        open={loading}
        TransitionComponent={Slide}
        message={__('جاري حذف البيانات...', 'Deleting record...')}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.text.primary
  },
  menuIconSmall: {
    color: theme.palette.text.primary,
    fontSize: 18
  }
}))

const ActionMenu = ({id, buttons = [], editAction, viewAction, printAction, normalView, rowData, protect = [], deleteRecord, endpoint}) => {
  const classes = useStyles()
  const popupState = usePopupState({variant: 'popover', popupId: 'actionMenu'})

  if (protect.includes(rowData.id)) return null

  return (
    <div>
      <Hidden mdDown>
        <div style={{whiteSpace: 'nowrap', textAlign: 'center'}}>
          {(buttons.includes('print')) && <IconButton onClick={() => {
            printAction && printAction(rowData)
            popupState.close()
          }}>
            <BiPrinter/>
          </IconButton>}

          <AccessGuard permission={'read'} endpoint={endpoint}>
            {buttons.includes('show') && (
              <>
                {normalView ? <IconButton component={Link} to={`${window.location.pathname}/${id}`}>
                  <BiDetail className={classes.menuIcon}/>
                </IconButton> : <IconButton onClick={() => {
                  popupState.close()
                  viewAction && viewAction(rowData)
                }}>
                  <BiDetail className={classes.menuIcon}/>
                </IconButton>}
              </>
            )}
          </AccessGuard>

          <AccessGuard permission={'edit'} endpoint={endpoint}>
            {(buttons.includes('edit')) && <IconButton onClick={() => {
              editAction && editAction(rowData)
              popupState.close()
            }}>
              <BiEdit className={classes.menuIcon}/>
            </IconButton>}
          </AccessGuard>

          <AccessGuard permission={'delete'} endpoint={endpoint}>
            {(buttons.includes('delete')) && <IconButton onClick={() => {
              deleteRecord()
              popupState.close()
            }}>
              <BiTrash style={{color: '#bf1111'}}/>
            </IconButton>}
          </AccessGuard>
        </div>
      </Hidden>

      <Hidden lgUp>
        <IconButton {...bindTrigger(popupState)}>
          <MoreVertIcon/>
        </IconButton>
        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          {(buttons.includes('print')) && <MenuItem onClick={() => {
            popupState.close()
            editAction && editAction(rowData)
          }}>
            <ListItemIcon>
              <BiPrinter className={classes.menuIconSmall}/>
            </ListItemIcon>
            <Typography variant="inherit">{__('طباعة', 'Print')}</Typography>
          </MenuItem>}

          <AccessGuard permission={'read'} endpoint={endpoint}>
            {buttons.includes('show') && (
              <>
                {normalView ? <MenuItem component={Link} to={`${window.location.pathname}/${id}`}>
                  <ListItemIcon>
                    <BiDetail className={classes.menuIconSmall}/>
                  </ListItemIcon>
                  <Typography variant="inherit"><FormattedMessage id="datatable.show" defaultMessage="Show"/></Typography>
                </MenuItem> : <MenuItem onClick={() => {
                  popupState.close()
                  viewAction && viewAction(rowData)
                }}>
                  <ListItemIcon>
                    <BiDetail className={classes.menuIconSmall}/>
                  </ListItemIcon>
                  <Typography variant="inherit"><FormattedMessage id="datatable.show" defaultMessage="Show"/></Typography>
                </MenuItem>}
              </>
            )}
          </AccessGuard>

          <AccessGuard permission={'edit'} endpoint={endpoint}>
            {(buttons.includes('edit')) && <MenuItem onClick={() => {
              popupState.close()
              editAction && editAction(rowData)
            }}>
              <ListItemIcon>
                <BiEdit className={classes.menuIconSmall}/>
              </ListItemIcon>
              <Typography variant="inherit"><FormattedMessage id="datatable.edit" defaultMessage="Edit"/></Typography>
            </MenuItem>}
          </AccessGuard>

          <AccessGuard permission={'delete'} endpoint={endpoint}>
            {(buttons.includes('delete')) && <MenuItem onClick={() => {
              deleteRecord()
              popupState.close()
            }}>
              <ListItemIcon>
                <BiTrash style={{color: '#bf1111'}} className={classes.menuIconSmall}/>
              </ListItemIcon>
              <Typography style={{color: '#bf1111'}} variant="inherit"><FormattedMessage id="datatable.delete" defaultMessage="Delete"/></Typography>
            </MenuItem>}
          </AccessGuard>
        </Menu>
      </Hidden>
    </div>
  )
}

export default ActionMenu
