import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"
import moment from "moment"
import {isDev} from "../../admin-utils/Components/Page/Page"

const useStyles = makeStyles((theme) => ({}))

function Orders() {
  const classes = useStyles()
  const tableRef = useRef()
  let formRef
  let formRef2
  return (
    <Page
      title={__('الطلبات', 'Orders')}
      left={() => (
        <Forms
          onRef={ref => formRef = ref}
          endpoint={'order'}
          params={{
            include: 'user,mall,service,service_type'
          }}
          title={__('اضافة طلب', 'Add Order')}
          editTitle={__('تعديل الطلب ', 'Edit Order')}
          width={600}
          // type={'drawer'}
          fields={[
            {
              title: __('التاريخ', 'Date'),
              field: 'created_at',
              type: 'date',
              rules: ['required'],
            },
            [
              {
                title: __('البلد', 'Country'),
                field: 'country_id',
                type: 'dropdown',
                endpoint: 'country',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                rules: ['required'],
              },
              {
                title: __('موقع العمل', 'Location'),
                field: 'mall_id',
                type: 'dropdown',
                endpoint: 'mall',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name_en',
                waitValue: 'country_id',
                rules: ['required'],
              },
            ],
            {
              title: __('المنطقة', 'Area Parking'),
              field: 'park_id',
              type: 'dropdown',
              endpoint: 'park',
              params: {
                limit: 1000
              },
              val: 'id',
              label: 'park',
              waitValue: 'mall_id',
              // rules: ['required'],
            },
            [
              {
                title: __('اسم الموظف', 'Employee Name'),
                field: 'user_id',
                type: 'dropdown',
                endpoint: 'users',
                params: {
                  limit: 1000
                },
                val: 'id',
                label: 'name',
                waitValue: 'mall_id',
                // rules: ['required'],
              },
              {
                title: __('الخدمة', 'Service'),
                field: 'service_id',
                type: 'dropdown',
                endpoint: 'service',
                params: {
                  limit: 1000,
                  include: 'service_type,car'
                },
                val: 'id',
                keys: ['car.name_en', 'service_type.name_en', 'price'],
                separator: '-',
                waitValue: 'country_id',
                rules: ['required'],

              },
            ],
            [
              {
                title: __('رقم السيارة', 'Car Number'),
                field: 'car_number',
                type: 'numeric',
                // rules: ['required'],
              },
              // car model
              {
                title: __('موديل السيارة', 'Car Model'),
                field: 'car_model_id',
                type: 'dropdown',
                endpoint: 'car_model',
                params: {
                  limit: 200
                },
                val: 'id',
                label: 'name',
              },
            ],
            [
              {
                title: __('طريقة الدفع', 'Payment Method'),
                field: 'payment_method',
                type: 'dropdown',
                // rules: ['required',],
                options: [
                  {id: 'visa', name: __('كريديت', 'Credit')},
                  {id: 'cash', name: __('كاش', 'Cash')},
                ],
              },
              {
                title: __('نسبة الخصم', 'Discount Percent'),
                field: 'discount_percent',
                type: 'numeric',
              },
              {
                title: __('الاجمالي', 'Total'),
                field: 'total',
                type: 'numeric',
                // rules: ['required'],
              },
            ],
            {
              title: __('اسم العميل', 'Client Name'),
              field: 'client_name',
              type: 'string',
              // rules: ['required'],
            },
            {
              title: __('جوال العميل', 'Client Mobile'),
              field: 'client_phone',
              type: 'string',
              // rules: ['required'],
            },
            // {
            //   title: __('الحالة', 'Status'),
            //   field: 'status',
            //   type: 'dropdown',
            //   options: [
            //     {id: 'processing', name: __('جاري العمل', 'Processing')},
            //     {id: 'completed', name: __('تم الانتهاء', 'Completed')},
            //   ],
            // },
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >
      <Forms
        hide
        onRef={ref => formRef2 = ref}
        endpoint={'order'}
        params={{
          include: 'car_model'
        }}
        title={__('تعيين موديل سيارة', 'Assign Car Model')}
        editTitle={__('تعيين موديل سيارة', 'Assign Car Model')}
        fields={[
          {
            title: __('موديل السيارة', 'Car Model'),
            field: 'car_model_id',
            type: 'dropdown',
            endpoint: 'car_model',
            params: {
              limit: 200
            }
          }
        ]}
        onFinish={() => {
          tableRef.current.onQueryChange()
        }}
      />

      <DataTable
        remoteExport
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة الطلبات', 'Listing Orders')}
        endpoint={'order'}
        summary={'order'}
        params={{
          include: 'mall,service,service.car,service.service_type,user,park,service.service_covers,service.service_covers.cover,'
        }}
        limit={isDev ? 10 : 200}
        // limit={10}
        // pagination={false}
        settings={{
          selection: true,
          deleteSelected: true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        inlineSearch
        columns={[
          {
            title: __('اليوم', 'Day'),
            field: 'created_at',
            type: 'date',
            dateFormat: 'dddd',
            exportDateFormat: 'l'
          },
          {
            title: __('التاريخ', 'Date'),
            field: 'created_at',
            type: 'date',
            dateFormat: 'DD-MM-YYYY'
          },
          {
            title: __('الشهر', 'Month'),
            field: 'created_at',
            type: 'date',
            dateFormat: 'MMMM'
          },
          {
            title: __('الوقت', 'Time'),
            field: 'created_at',
            type: 'date',
            dateFormat: 'HH:MM ',
            exportDateFormat: 'H:i'
          },
          {
            title: __(' موقع العمل ', 'Location'),
            field: 'mall.name_en',
            type: 'string',
            sorting: false
          },
          {
            title: __('المنطقة', 'Area Parking'),
            field: 'park.park',
            type: 'string',
            sorting: false
          },
          {
            title: __('اسم الموظف', 'Employee Name'),
            field: 'user.name',
            type: 'string',
            sorting: false
          },
          {
            title: __('رقم الموظف', 'Employee Id'),
            field: 'user.code',
            type: 'number',
            sorting: false
          },
          {
            title: __('اسم العميل', 'Client Name'),
            field: 'client_name',
            type: 'string',
          },
          {
            title: __('هاتف العميل ', 'Client Phone'),
            field: 'client_phone',
            type: 'string',
          },
          {
            title: __(' رقم السيارة ', 'Car Number'),
            field: 'car_number',
            type: 'numeric',
          },
          // {
          //   title: __('الحالة', 'Status'),
          //   field: 'status',
          //   type: 'string',
          //   options: [
          //     {id: 'processing', name: __('جاري العمل', 'Processing')},
          //     {id: 'paid', name: __('تم الانتهاء', 'Completed')},
          //     {id: 'completed', name: __('تم الانتهاء', 'Completed')},
          //     // {id: 'paid', name: __('مدفوعة', 'Paid')},
          //   ],
          //   colors: [{
          //     value: 'processing',
          //     compare: '=',
          //     color: 'yellow[900]'
          //   }, {
          //     value: 'paid',
          //     compare: '=',
          //     color: 'green[600]'
          //   }],
          // },
          {
            title: __(' السيارة', 'Car'),
            field: 'service.car.name_en',
            type: 'string',
            sorting: false,
          },
          {
            title: __('الموديل', 'Model'),
            field: 'car_model.name',
            sorting: false,
            type: 'string',
            onClick: {
              action: (column, rowData) => {
                formRef2.show(rowData.id)
              },
              type: "link",
            }
          },
          {
            title: __(' الخدمة', 'Service'),
            field: 'service.service_type.name_en',
            type: 'string',
            summary: 'count',
            sum_after: 'orders',
            sorting: false,
            onClick: {
              popover: (data) => (
                <DetailsTable
                  inline={true}
                  data={data}
                  rows={[
                    {
                      title: __('الاغطية المجانية', 'Free Covers'),
                      field: 'service.service_covers',
                      type: 'tags',
                      key: [
                        'cover.name_en',
                        'quantity'
                      ],
                      separator: ' (',
                      after: ')'
                    },
                  ]}
                />
              ),
              title: __('عرض التفاصيل', 'Show Details'),
              type: 'link',
            }
          },
          {
            title: __('الاجمالي', 'Total'),
            field: 'total',
            type: 'money',
            summary: 'sum',
          },
          {
            title: __('طريقة الدفع', 'Payment Method'),
            field: 'payment_method',
            type: 'string',
            options: [
              {id: 'visa', name: __('كريديت', 'Credit')},
              {id: 'cash', name: __('كاش', 'Cash')},
            ],
            colors: [{
              value: 'visa',
              compare: '=',
              color: 'pink[700]'
            }, {
              value: 'cash',
              compare: '=',
              color: 'cyan[700]'
            }],
            summary: 'count',
          },
          // {
          //   title: __(' تعيين الموظف', 'Assign Employee'),
          //   onClick: {
          //     action: (columnData, rowData) => {
          //       formRef2.show(rowData.id)
          //     },
          //     title: __('تعيين', 'Assign'),
          //     type: 'button',
          //     value: __('تم التعيين', 'Assigned'),
          //   }
          // },
        ]}
        search={[{
          title: __(' التاريخ', 'Date'),
          field: 'created_at',
          type: 'date',
          filter: 'range',
        },
          [
            {
              title: __('كود الموظف', 'Employee Code'),
              field: 'include,user,code',
              type: 'string',
            },
            {
              title: __('اسم الموظف', 'Employee Name'),
              field: 'include,user,name',
              type: 'string',
              filter: 'like',
            },
            {
              title: __('اختر الموظف', 'Choose Employee'),
              field: 'user_id',
              type: 'dropdown',
              endpoint: 'users',
              params: {
                limit: 1000
              },
              val: 'id',
              // multiple: true,
              // waitValue: 'mall_id',
              keys: ['name', 'code'],
              separator: '-',
            },],
          [
            {
              title: __(' اسم الدولة ', '  Country Name'),
              field: 'country_id',
              type: 'dropdown',
              endpoint: 'country',
              params: {
                limit: 1000
              },
              val: 'id',
              multiple: true,
              label: 'name_en',
            },
            {
              title: __(' موقع العمل', 'Location '),
              field: 'mall_id',
              type: 'dropdown',
              endpoint: 'mall',
              params: {
                limit: 1000
              },
              val: 'id',
              // multiple: true,
              label: 'name_en',
            }],
          [
            {
              title: __(' رقم الطلب', 'Order No.'),
              field: 'id',
              type: 'numeric',
              columns: 3,
            },
            {
              title: __('الخدمة', 'Service'),
              field: 'include,service,service_type_id',
              type: 'dropdown',
              endpoint: 'service_type',
              params: {
                limit: 1000
              },
              val: 'id',
              label: 'name_en',
            }, {
            title: __(' السيارة', 'Car '),
            field: 'include,service,car_id',
            type: 'dropdown',
            endpoint: 'car',
            params: {
              limit: 1000
            },
            val: 'id',
            label: 'name_en',
            columns: 3,
          },
            {
              title: __('طرق الدفع ', 'Pay Method '),
              field: 'payment_method',
              type: 'dropdown',
              options: [
                {id: 'visa', name: __('كريديت', 'Credit')},
                {id: 'cash', name: __('كاش', 'Cash')},
              ],
              columns: 3,
            },
          ],
          // {
          //   title: __('الحالة', 'Status'),
          //   field: 'status',
          //   type: 'dropdown',
          //   options: [
          //     {id: 'processing', name: __('جاري العمل', 'Processing')},
          //     {id: 'paid', name: __('مدفوعة', 'Paid')},
          //   ],
          // },
        ]}
      />
    </Page>
  )
}

export default Orders
