import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles';
import {DetailsCard, DetailsTable, DetailsContainer, Page, DataTable, Forms} from "src/admin-utils"
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router"
import __ from "../../admin-utils/LanguageHelper";

const useStyles = makeStyles((theme) => ({}));

function SettingsServiceTypes() {
  const tableRef = useRef()
  let formRef
  let {id} = useParams()
  const [updater, setUpdater] = useState(false)

  return (
    <Page
      title={__('انواع الخدمات', 'Service Types')}
      left={() => (
        <Forms
          onRef={ref => formRef = ref}
          title={__('اضافة نوع خدمة جديد', 'Add New service Type')}
          editTitle={__('تعديل نوع الخدمة', 'Edit service Type')}
          endpoint={'service_type'}
          fields={[
            // {
            //   title: __(' يمكنك رفع ايقونة الخدمة', 'Upload service Icon'),
            //   field: 'image',
            //   type: 'image',
            //   rules: ['required',],
            // },
            [
              {
                title: __(' نوع الخدمة (بالانجليزية)', 'Service Type (en)'),
                field: 'name_en',
                type: 'string',
                rules: ['required',],
              },
              {
                title: __(' نوع الخدمة (بالعربية)', 'Service Type (ar)'),
                field: 'name_ar',
                type: 'string',
                rules: ['required',],
              },
            ],
          ]}
          onFinish={() => {
            tableRef.current.onQueryChange()
          }}
        />
      )}
    >

      <DataTable
        ref={tableRef}
        editAction={(id) => {
          formRef.show(id)
        }}
        title={__('قائمة انواع الخدمات', 'Listing Service Types')}
        endpoint={'service_type'}
        buttons={["edit", "delete",]}
        settings={{
          selection: true,
          deleteSelected:true,
          // maxBodyHeight: 'calc(100vh - 350px)'
        }}
        columns={[
          // {
          //   title: __('ايقونة', 'Icon'),
          //   field: 'image',
          //   type: 'image',
          //   width: 80
          // },
          {
            title: __(' نوع الخدمة ', '  Service Type'),
            field: 'name_en',
            type: 'string',
          },
          // {
          //   title: <FormattedMessage id={'tables.services.name_ar'} defaultMessage={'Service Type (Arabic)'}/>,
          //   field: 'name_ar',
          //   type: 'string',
          // },
        ]}
        search={[
          {
            title: __(' نوع الخدمة (بالانجليزية)', 'Service Type (en)'),
            field: 'name_en',
            type: 'string',
            filter: 'like'
          },
          {
            title: __(' نوع الخدمة (بالعربية)', 'Service Type (ar)'),
            field: 'name_ar',
            type: 'string',
          },
        ]}
      />
    </Page>
  );
}

export default SettingsServiceTypes;
