import __ from "../admin-utils/LanguageHelper"

const baseDomain = 'https://api.parknwash.xyz'
const baseUrl = baseDomain + '/v1'
const authables = []
const currency = __('دينار', 'KWD')

// Form Options
export const formOptions = {
  keepData: false,
  clearInputs: false,
  maxUpload: 5000
}

// Table Options
export const tableOptions = {
  dateFormat: 'dddd DD-MM-YYYY',
  autoSerialNumber: true,
  sortable: false,
  defaultExport: 'sum'
}

// PDF Options
export const pdfOptions = {
  allowPrint: false,
  logo: null
}

// Global Options
export const globalOptions = {
  enableAccessGuard: false,
}

export const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {})

export {
  baseUrl,
  baseDomain,
  authables,
  currency
}
