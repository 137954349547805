import React, {forwardRef, useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {makeStyles} from "@material-ui/styles"
import MaterialTable, {MTableBody, MTableToolbar} from "@material-table/core"
import Typography from "@material-ui/core/Typography"
import SearchIcon from "@material-ui/icons/Search"
import _, {omit} from "lodash"
import {FormattedMessage} from "react-intl"
import "./DataTable.scss"
import {useSelector} from "react-redux"
import {getData} from "../../API"
import useTheme from "@material-ui/core/styles/useTheme"
import ActionMenu, {DeleteConfirm} from "./ActionMenu"
import arabic from "./Languages/arabic"
import english from "./Languages/english"
import FilterPanel from "./FilterPanel"
import ColumnDataRender from "./ColumnDataRender"
import AccessGuard, {UserCanDo} from "../AccessGuard"
import {Button, colors as coreColors, fade} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import {
  authables,
  baseUrl,
  pdfOptions,
  tableOptions
} from "../../../utils/globals"
import IconButton from "@material-ui/core/IconButton"
import Badge from "@material-ui/core/Badge"
import TableFooter from "@material-ui/core/TableFooter"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import __ from "../../LanguageHelper"
import * as numeral from "numeral"
import XLSX from "xlsx"
import moment from "moment"
import {GridLoader} from "react-spinners"
import {chipLabel} from "./ColumnDataRender/ColumnDataRender"
import PrintingView from "./PrintingView"
import DetailsPanel from "../Page/DetailsPanel"
import SortIcon from "@material-ui/icons/Sort"
import SortingPanel from "./SortingPanel"
import PrintableDocument from "./PrintingView/PrintableDocument"
import download from "downloadjs"
import axios from "axios"
import ExportPanel from "./ExportPanel/ExportPanel"
import adminUtils from "../../../locales/adminUtils"
import {useHistory, useParams} from "react-router"

const useStyles = makeStyles(theme => ({
  stickyActionsColumn: {
    "& table:first-child": {
      "& tr": {
        "& td:last-child, th:last-child": {
          backgroundColor: "#F4F6F8",
          position: "sticky",
          right: 0,
          width: 50
        }
      }
    }
  },
  footerRow: {
    backgroundColor: theme.palette.primary.main,
    "& *": {
      color: theme.palette.primary.contrastText
    }
  },
  simpleTable: {
    marginTop: theme.spacing(1)
  },
  actionColumn: {
    display: "flex",
    alignItems: "center",
    paddingRight: 15
  },
  toolbarColumn: {
    flex: 1
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "row"
  },
  buttonTitle: {
    marginLeft: 10
  }
}))

const AddFormTrigger = ({editAction, addTitle}) => {
  const classes = useStyles()
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={() => {
        editAction && editAction()
      }}
    >
      <AddIcon/>{" "}
      {addTitle && <span className={classes.buttonTitle}>{addTitle}</span>}
    </Button>
  )
}

const DataTable = forwardRef(
  (
    {
      endpoint,
      ignorePermissions,
      params = {},
      details,
      title,
      reportTitle,
      columns = [],
      buttons = ["show", "delete", "edit"],
      simple,
      pdfExport,
      withActions,
      editAction,
      addAction,
      printableComponent,
      printableTitle,
      protect,
      hideProtected,
      settings,
      formRef,
      addTitle,
      search,
      summary,
      colors,
      strips,
      limit = 10,
      viewAction,
      onDelete,
      remoteExport = false,
      canExport = true,
      pagination = true,
      printable = true,
      inlineSearch,
      searchHidden = {},
      printStyles = {},
      printTitle = null,
      inlineSearchWidth,
      onLoad = null,
      exportParams = null,
      pageSizeOptions = [5, 10, 20, 30, 50, 100],
      onSelectionChange,
      selectionButtons,
      autoSerialNumber = false,
      autoIDColumn = true,
      sortable = false,
      hide = false,
      sorterLabel = null,
      clickableRow = false,
      primaryKey = 'id',
      defaultOrder = null,
      defaultOrderDirection = "desc",
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()
    const {token} = useSelector(state => state.session)

    const theme = useTheme()
    const history = useHistory()

    // Table ref
    let tableRef = useRef()
    if (ref) {
      tableRef = ref
    }

    // Auto inserting Serial number
    const [allowAutoSerialNumber] = useState(
      tableOptions.autoSerialNumber || autoSerialNumber
    )
    const [allowAutoIDColumn] = useState(
      tableOptions.autoIDColumn || autoIDColumn
    )

    // Sortable rows
    const [allowSortable] = useState(tableOptions.sortable || sortable)
    const [onScreenData, setOnScreenData] = useState({})

    // Delete Confirm
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [recordID, setRecordID] = useState(false)

    // Selection
    const [selectedRows, setSelectedRows] = useState([])

    // Localization
    const savedDirection = localStorage.getItem("direction")
    let localization = savedDirection === "rtl" ? arabic : english

    const [records, setRecords] = useState({total: 0, current: 0, pages: 1})

    // Format columns
    let formattedColumns = [],
      sumCols = []

    columns.map(column => {
      if (!column.permission || UserCanDo(column.permission, endpoint)) {
        column.headerStyle = {whiteSpace: column.wrap ? "normal" : "nowrap"}

        if (column.multi) {
          column.filtering = false
        }

        if (column.width) {
          column.headerStyle.width = column.width
        }

        if (column.summary) {
          sumCols.push(`${column.field}:${column.summary}`)
        }

        formattedColumns.push({
          ...column,
          render: rowData => (
            <div
              style={{
                minWidth: column.width ? column.width : null,
                whiteSpace: column.wrap ? "normal" : "nowrap"
              }}
            >
              <ColumnDataRender
                rowData={rowData}
                column={column}
                endpoint={endpoint}
                tableRef={tableRef}
              />
            </div>
          )
        })
      }
    })

    // Summary
    const [sumColumns] = useState(sumCols)
    const [sumData, setSumData] = useState([])

    let tableColumns = []

    // Serial Number
    if (allowAutoSerialNumber) {
      tableColumns.push({
        title: "#",
        field: "#",
        filtering: false,
        width: 50
      })
    }

    // ID
    if (allowAutoIDColumn) {
      tableColumns.push({
        title: adminUtils.table.IDTitle || __("م", "ID"),
        field: primaryKey,
        filtering: false,
        width: 50
      })
    }

    tableColumns = [...tableColumns, ...formattedColumns]

    // Columns object
    let [columnObject, setColumnObject] = useState(tableColumns)

    const componentRef = useRef()

    useEffect(() => {
      if (buttons && buttons.length > 0 && (!simple || withActions)) {
        tableColumns = [
          ...columnObject,
          ...[
            {
              filtering: false,
              sorting: false,
              title: __("الإجراء", "Action"),
              field: "action",
              render: rowData => (
                <ActionMenu
                  id={rowData[primaryKey]}
                  endpoint={endpoint}
                  formRef={formRef}
                  buttons={buttons}
                  editAction={() => {
                    editAction && editAction(rowData[primaryKey], rowData)
                  }}
                  normalView={!viewAction}
                  viewAction={() => {
                    viewAction && viewAction(rowData[primaryKey], rowData)
                  }}
                  printAction={() => {
                    if (printableComponent) {
                      DetailsPanel.show({
                        stay: true,
                        drawerWidth: 850,
                        view: (
                          <PrintableDocument
                            title={printableTitle}
                            component={printableComponent}
                            id={rowData[primaryKey]}
                            data={rowData}
                          />
                        )
                      })
                    }
                  }}
                  rowData={rowData}
                  protect={protect}
                  deleteRecord={() => {
                    setRecordID(rowData[primaryKey])
                    setShowDeleteConfirm(true)
                  }}
                />
              ),
              headerStyle: {textAlign: "center"},
              width: 150
            }
          ]
        ]
      } else {
        tableColumns = [...columnObject].filter(c => c.field !== "action")
      }

      setColumnObject(tableColumns)
    }, [withActions])

    // Search drawer
    const [filterOpen, setFilterOpen] = useState(false)

    const toggleFilter = () => {
      setFilterOpen(!filterOpen)
    }

    // Action buttons
    const canSearch = search && _.isArray(search) && search.length > 0
    let actions = []

    const {allowPrint} = pdfOptions

    if (allowPrint && printable) {
      actions.push({
        isFreeAction: true,
        tooltip: __("طباعة", "Print"),
        icon: "print",
        onClick: (evt, data) => {
          printTaleData().finally()
        }
      })
    }

    actions.push({
      isFreeAction: true,
      tooltip: __("اعادة تحميل", "Reload Data"),
      icon: "sync",
      onClick: (evt, data) => {
        tableRef.current.onQueryChange()
      }
    })

    const [filters, setFilters] = useState([])
    if (!simple && canSearch && !inlineSearch && filters.length === 0) {
      actions.push({
        isFreeAction: true,
        tooltip: __("بحث", "Search"),
        icon: "search",
        onClick: (evt, data) => {
          toggleFilter()
        }
      })
    }

    const printTaleData = async () => {
      const {formattedData, headers} = await getTableData(false, true)

      window.history.pushState({}, "", `${endpoint}/print`)

      DetailsPanel.show({
        view: (
          <PrintingView
            title={printTitle || title}
            headers={headers}
            data={formattedData}
            sumData={sumData}
            columnObject={columnObject}
          />
        )
      })
    }

    // Page size
    let [pageSize, setPageSize] = useState(limit)

    // Filter
    const applyFilter = filtering => {
      tableRef.current.onQueryChange({filtering})
      setFilters(filtering)
      // console.log(filtering)
    }

    // Colors
    function calculateColor(data) {
      let color = null
      for (let i = 0; i <= colors.length; i++) {
        if (colors[i]) {
          let col = colors[i],
            value = col.value,
            columnColor = col.color

          let rawData = data[col.field]

          switch (col.compare) {
            case ">":
              color = rawData > value ? columnColor : null
              break
            case "<":
              color = rawData < value ? columnColor : null
              break
            case "=":
              color = rawData === value ? columnColor : null
              break
            case ">=":
              color = rawData >= value ? columnColor : null
              break
            case "<=":
              color = rawData <= value ? columnColor : null
              break
            case "<>":
              color = rawData !== value ? columnColor : null
              break
          }

          if (color) return _.get(coreColors, color)
        }
      }

      return null
    }

    // Export excel
    const [queryParams, setQueryParams] = useState({})
    const [isLoading, setLoading] = useState(false)

    function canBeExported(column) {
      // Excluded types
      const excludedColumnTypes = ["image"]
      if (excludedColumnTypes.includes(column.type)) return false

      // Exclude buttons
      if (column.onClick) return false

      // Excluded from code
      if (column.noExport) return false

      // Can be exported
      return true
    }

    const getTableData = async (includeHeaders = true, print = false) => {
      setLoading(true)

      let tableColumns = [
        {
          title: __("م", "ID"),
          field: primaryKey,
          filtering: false,
          width: 50
        }
      ]

      tableColumns = [...tableColumns, ...formattedColumns]

      if (authables.includes(endpoint)) {
        endpoint = `auth_${endpoint}`
      }

      queryParams.limit = 10000
      queryParams.page = 1

      if (exportParams) {
        Object.assign(queryParams, exportParams)
      }

      let result = await getData(endpoint, queryParams, token)
      let data = result.data
      let formattedData = []

      let headers = [],
        columnTitle = ""
      tableColumns.map(column => {
        if (canBeExported(column)) {
          columnTitle = _.isObject(column.title)
            ? column.title.props.defaultMessage
            : column.title
          headers.push(columnTitle)
        }
      })

      if (includeHeaders) {
        formattedData.push(headers)
      }

      data.map(item => {
        let itemArray = []
        tableColumns.map(column => {
          if (canBeExported(column)) {
            let after = column.after || ""

            let columnData = _.get(item, column.field)

            if (column.options) {
              let found = column.options.find(o => o[primaryKey] === columnData)
              columnData = found ? found.name : columnData
            }

            if (column.multi) {
              let regex = /\(([^)]+)\)/g
              let fields = column.field.match(regex),
                fieldOutput = column.field

              if (Array.isArray(fields)) {
                fields.map(field => {
                  let fieldData = _.get(item, field.replace(/[{()}]/g, ""))
                  fieldOutput = fieldOutput.replace(field, fieldData)
                })

                columnData = fieldOutput
              }
            }

            if (column.type === "chips" || column.type === "tags") {
              if (_.isArray(columnData)) {
                let fieldOutput = []
                columnData.map((item, index) => {
                  fieldOutput.push(chipLabel(column, item))
                })

                columnData = fieldOutput.join(" | ")
              }
            }

            if (column.type === "money") {
              columnData = `${numeral(columnData).format("0,0")} ${after}`
            }

            if (column.exportValue) {
              columnData = column.exportValue(item)
            }

            if (print) {
              itemArray.push({
                width: column.printWidth,
                value: columnData
              })
            } else {
              itemArray.push(columnData)
            }
          }
        })

        formattedData.push(itemArray)
      })

      setLoading(false)

      return {
        formattedData,
        headers
      }
    }

    const pdfExporter = async () => {
      let tableColumns = [
        {
          title: __("م", "ID"),
          field: primaryKey,
          filtering: false,
          width: 50
        }
      ]

      tableColumns = [...tableColumns, ...formattedColumns]

      tableColumns = tableColumns.filter(i => !!i.field)

      // setLoading(true)

      const request = await axios({
        url: `${baseUrl}/pdf/export`,
        method: "POST",
        responseType: "blob",
        data: {
          language: __("ar", "en"),
          endpoint,
          tableColumns,
          title
        },
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: 0
      })

      // Download PDF file
      download(
        new Blob([request.data]),
        `exported-data-${moment().format("DD-MM-YYYY HHmmss")}.pdf`
      )

      setLoading(false)
    }

    const remoteExporter = async (options = null) => {
      let tableColumns = [
        {
          title: __("م", "ID"),
          field: primaryKey,
          filtering: false,
          width: 50
        }
      ]

      tableColumns = [...tableColumns, ...formattedColumns]

      if (authables.includes(endpoint)) {
        endpoint = `auth_${endpoint}`
      }

      // queryParams.limit = 10000
      // queryParams.page = 1

      if (exportParams) {
        Object.assign(queryParams, exportParams)
      }

      let headers = []
      headers.push({
        title: '#',
        field: 'serial',
        type: 'serial'
      })

      tableColumns.map(column => {
        if (canBeExported(column)) {
          headers.push(column)
        }
      })

      if (!options) {
        DetailsPanel.show({
          modal: true,
          stay: true,
          drawerWidth: 500,
          view: (
            <ExportPanel
              title={reportTitle || title}
              headers={headers}
              onDone={options => {
                remoteExporter(options).finally()
              }}
            />
          )
        })

        return
      }

      DetailsPanel.hide()
      setLoading(true)

      const request = await axios({
        url: `${baseUrl}/excel/export`,
        method: "POST",
        responseType: "blob",
        data: {
          language: __("ar", "en"),
          endpoint,
          headers: options.headers,
          title: options.title
        },
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: 0
      })

      // Download Excel file
      download(
        new Blob([request.data]),
        `exported-data-${moment().format("DD-MM-YYYY HHmmss")}.xlsx`
      )

      setLoading(false)
    }

    const excelExport = async () => {
      const {formattedData, headers} = await getTableData()

      /* convert state to workbook */
      const ws = XLSX.utils.aoa_to_sheet(formattedData)

      _.forEach(ws, (v, c) => {
        if (c !== "!ref") {
          if (headers.indexOf(v.v) >= 0) {
            ws[c]["s"] = {
              fill: {
                patternType: "solid", // none / solid
                fgColor: {rgb: "FFD3D3D3"}
              }
            }
          }
        }
      })

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
      /* generate XLSX file and send to client */
      XLSX.writeFile(
        wb,
        `exported-data-${moment().format("DD-MM-YYYY HHmmss")}.xlsx`
      )
    }

    const openSortingPanel = () => {
      if (_.isObject(endpoint)) {
        endpoint = endpoint.post
      }

      DetailsPanel.show({
        modal: true,
        stay: true,
        drawerWidth: 500,
        view: (
          <SortingPanel
            data={onScreenData.data}
            startIndex={onScreenData.startIndex}
            sorterLabel={sorterLabel}
            endpoint={endpoint}
            onLoad={() => {
              tableRef.current.onQueryChange()
              DetailsPanel.hide()
            }}
          />
        )
      })
    }

    if (hide) {
      return null
    }

    function getExportMenu() {
      const exportMenu = []

      if (canExport && !simple) {
        exportMenu.push({
          label: __('تصدير البيانات اكسل', 'Export Excel'),
          exportFunc: (columns, data) => {
            if (remoteExport) {
              remoteExporter().finally()
            } else {
              excelExport(columns, data).finally()
            }
          }
        })
      }

      if (pdfExport) {
        exportMenu.push({
          label: __('تصدير البيانات PDF', 'Export PDF'),
          exportFunc: (columns, data) => {
            pdfExporter().finally()
          }
        })
      }

      return exportMenu
    }

    const userCanView = UserCanDo('read', endpoint) && !ignorePermissions

    const onRowClick = (event, rowData) => {
      if (!clickableRow) return

      if (!userCanView) return

      if (_.isFunction(clickableRow)) {
        clickableRow(rowData)
      } else {
        if (viewAction) {
          viewAction(rowData[primaryKey], rowData)
        } else {
          history.push(`${window.location.pathname}/${rowData[primaryKey]}`)
        }
      }
    }

    return (
      <>
        <AccessGuard permission={"browse"} endpoint={endpoint} ignorePermissions={ignorePermissions}>
          {pagination && !simple && (
            <Typography
              variant={"body2"}
              gutterBottom
              style={{marginTop: 10}}
            >
              {__('اجمالي النتائج ({total}) - صفحة {current} من {pages}', '{total} Records found. Page {current} of {pages}', records)}
            </Typography>
          )}

          {canSearch && !inlineSearch && (
            <FilterPanel
              filterOpen={filterOpen}
              toggleFilter={toggleFilter}
              fields={search}
              applyFilter={applyFilter}
              filters={filters}
              searchHidden={searchHidden}
            />
          )}

          <DeleteConfirm
            open={showDeleteConfirm}
            handleClose={() => setShowDeleteConfirm(false)}
            tableRef={tableRef}
            endpoint={endpoint}
            id={recordID}
            onDelete={onDelete}
          />

          <div
            className={
              simple || buttons.length === 0
                ? classes.simpleTable
                : classes.stickyActionsColumn
            }
          >
            <MaterialTable
              isLoading={isLoading}
              tableRef={tableRef}
              title={title}
              columns={columnObject}
              options={{
                filtering: false,
                search: false,
                draggable: false,
                exportMenu: getExportMenu(),
                selection: false,
                headerStyle: {
                  fontWeight: 600,
                  backgroundColor: "#F4F6F8"
                },
                paging: pagination && !simple,
                pageSize,
                pageSizeOptions,
                rowStyle: rowData => {
                  if (strips) {
                    return {
                      backgroundColor:
                        rowData.tableData[primaryKey] % 2 ? "#f8fafc" : "#FFF"
                    }
                  }

                  if (colors) {
                    return {
                      backgroundColor: calculateColor(rowData)
                    }
                  }
                },
                ...settings
              }}
              onSelectionChange={rows => {
                setTimeout(() => {
                  setSelectedRows(rows)
                }, 10)
              }}
              actions={[
                ...actions,
                settings &&
                settings.selection &&
                settings.deleteSelected && {
                  tooltip: __("حذف كل المحدد", "Remove all selected"),
                  icon: "delete",
                  onClick: (evt, data) => {
                    let ids = data.map(i => i[primaryKey])

                    setRecordID(ids)
                    setShowDeleteConfirm(true)
                  }
                }
              ]}
              data={query => {
                // console.log(query)
                setPageSize(query.pageSize)

                let orderBy = _.get(query, "orderBy.field")

                return new Promise(async (resolve, reject) => {
                  let queryParams = {
                    limit: query.pageSize,
                    page: query.page + 1
                  }

                  if (query.filters.length > 0) {
                    let search = ""
                    query.filters.map(filter => {
                      search += `${filter.column.field}:${filter.value};`
                    })

                    queryParams.search = search
                  }

                  if (query.filtering) {
                    queryParams.filtering = []
                    query.filtering.map(filter => {
                      if (filter.param) {
                        queryParams[filter.key] = filter.value
                      } else {
                        queryParams.filtering.push(filter)
                      }
                    })

                    // Hidden fields
                    if (Object.keys(searchHidden).length > 0) {
                      Object.keys(searchHidden).map(key => {
                        queryParams[key] = searchHidden[key]
                      })
                    }
                  }

                  if (orderBy && !orderBy.includes(".")) {
                    queryParams.orderBy = orderBy || "id"
                  }

                  if (!queryParams.orderBy) {
                    queryParams.orderBy = defaultOrder || primaryKey
                  }

                  queryParams.sortedBy = query.orderDirection || defaultOrderDirection
                  queryParams.requestedBy = "dashboard"

                  if (allowSortable && queryParams.orderBy === primaryKey) {
                    queryParams.orderBy = "sorter"
                    queryParams.sortedBy = "asc"
                  }

                  let paramsKeys = Object.keys(params)
                  if (paramsKeys.length > 0) {
                    paramsKeys.map(param => {
                      queryParams[param] = params[param]
                    })
                  }

                  if (authables.includes(endpoint)) {
                    endpoint = `auth_${endpoint}`
                  }

                  // Summary fetch
                  if (summary && query.page === 0) {
                    queryParams.summary = sumColumns.join(";")
                    queryParams.endpoint = summary

                    let sumParams = {...queryParams}
                    delete sumParams.limit
                    delete sumParams.orderBy
                    delete sumParams.sortedBy
                    delete sumParams.requestedBy
                    delete sumParams.page

                    getData(`reports`, sumParams, token).then(summaryFetch => {
                      setSumData(summaryFetch)
                    })
                  }

                  setQueryParams(queryParams)

                  let result = await getData(endpoint, queryParams, token)

                  let currentPage = _.get(
                      result,
                      "meta.pagination.current_page"
                    ),
                    totalCount = _.get(result, "meta.pagination.total"),
                    totalPages = _.get(result, "meta.pagination.total_pages")

                  let data = result ? result.data : []
                  data = data.map((item, index) => ({
                    ...item,
                    "#": (currentPage - 1) * query.pageSize + (index + 1)
                  }))

                  if (hideProtected) {
                    data = data.filter(item => !protect.includes(item[primaryKey]))
                  }

                  resolve({
                    data,
                    page: result ? currentPage - 1 : 1,
                    totalCount: result ? totalCount : 0
                  })

                  setRecords({
                    total: result ? totalCount : 0,
                    current: result ? currentPage : 0,
                    pages: result ? totalPages : 0
                  })

                  setOnScreenData({
                    data,
                    startIndex: (currentPage - 1) * query.pageSize + 1
                  })

                  onLoad && onLoad(data)
                })
              }}
              localization={localization.datatable}
              icons={{
                Filter: SearchIcon
              }}
              components={{
                OverlayLoading: props => (
                  <div
                    style={{
                      display: "table",
                      width: "100%",
                      height: "100%",
                      backgroundColor: fade(
                        props.theme.palette.background.paper,
                        0.7
                      )
                    }}
                  >
                    <div
                      style={{
                        display: "table-cell",
                        width: "100%",
                        height: "100%",
                        verticalAlign: "middle",
                        textAlign: "center"
                      }}
                    >
                      <div style={{display: "table", margin: "0 auto"}}>
                        <GridLoader
                          color={theme.palette.primary.main}
                          size={10}
                        />
                      </div>
                    </div>
                  </div>
                ),
                Toolbar: props => (
                  <>
                    <div className={classes.toolbarContainer}>
                      {buttons.includes("add") ? (
                        <>
                          <div className={classes.toolbarColumn}>
                            <MTableToolbar {...props} />
                          </div>
                          <div className={classes.actionColumn}>
                            <AccessGuard permission={"add"} endpoint={endpoint}>
                              <AddFormTrigger
                                addTitle={addTitle}
                                editAction={() => {
                                  if (addAction) {
                                    return addAction()
                                  }

                                  editAction && editAction()
                                }}
                              />
                            </AccessGuard>
                          </div>
                        </>
                      ) : (
                        <div className={classes.toolbarColumn}>
                          <MTableToolbar {...props} />
                        </div>
                      )}

                      {!inlineSearch && filters.length > 0 && (
                        <div className={classes.actionColumn}>
                          <IconButton
                            aria-label="search"
                            onClick={toggleFilter}
                          >
                            <Badge
                              color="secondary"
                              badgeContent={filters.length}
                            >
                              <SearchIcon/>
                            </Badge>
                          </IconButton>
                        </div>
                      )}

                      {allowSortable && (
                        <div className={classes.actionColumn}>
                          <IconButton
                            aria-label="search"
                            onClick={openSortingPanel}
                          >
                            <SortIcon/>
                          </IconButton>
                        </div>
                      )}

                      {settings && settings.selectionButtons && (
                        <div className={classes.actionColumn}>
                          {settings.selectionButtons(selectedRows)}
                        </div>
                      )}
                    </div>

                    {inlineSearch && (
                      <div style={{maxWidth: inlineSearchWidth}}>
                        <FilterPanel
                          inline
                          fields={search}
                          applyFilter={applyFilter}
                          filters={filters}
                        />
                      </div>
                    )}
                  </>
                ),
                Body: props => (
                  <>
                    <MTableBody {...props} />

                    {summary && Array.isArray(sumData) && (
                      <TableFooter>
                        <TableRow className={classes.footerRow}>
                          {columnObject.map((column, i) => {
                            let colSum = sumData.find(
                              s => s.column === column.field
                            )

                            if (i === 0)
                              return (
                                <TableCell colSpan={(details || _.get(settings, 'selection')) ? 2 : 1}>
                                  <Typography variant={"h6"}>
                                    {__("إجمالي:", "Total:")}
                                  </Typography>
                                </TableCell>
                              )

                            if (!colSum) return <TableCell/>

                            const val = colSum.value

                            if (column.type === "money" && colSum.value) {
                              colSum.value = numeral(colSum.value).format(
                                "0,0.00"
                              )
                            }

                            if (column.summary === "avg" && colSum.value) {
                              colSum.value = numeral(colSum.value).format(
                                "0.00"
                              )
                            }

                            return (
                              <TableCell>
                                <Typography
                                  variant={"body1"}
                                  style={{whiteSpace: "nowrap"}}
                                >
                                  {column.sum_before || ""} {column.summaryComponent ? column.summaryComponent(val) : colSum.value} {column.sum_after || ""}
                                </Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableFooter>
                    )}
                  </>
                )
              }}
              detailPanel={details ? rowData => details(rowData) : null}
              onRowClick={(event, rowData) => onRowClick(event, rowData)}
              {...omit(rest, ["data"])}
            />
          </div>
        </AccessGuard>
      </>
    )
  }
)

DataTable.propTypes = {
  title: PropTypes.any,
  endpoint: PropTypes.string,
  columns: PropTypes.array
}

export default DataTable
