/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import EnhancedEncryptionOutlinedIcon from '@material-ui/icons/EnhancedEncryptionOutlined';
import CastForEducationOutlinedIcon from '@material-ui/icons/CastForEducationOutlined';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';

import {FormattedMessage} from "react-intl"
import __ from "../../admin-utils/LanguageHelper"
import ScatterPlotOutlinedIcon from "@material-ui/icons/ScatterPlotOutlined"
import LocalCarWashOutlinedIcon from "@material-ui/icons/LocalCarWashOutlined"

export default [
  {
    subheader: "Admin Controls",
    items: [
      {
        title: "Dashboard",
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: "Orders",
        href: '/orders',
        icon: DashboardIcon
      },
      {
        title: "Stock Out",
        href: '/stock/out',
        icon: DashboardIcon
      },
      {
        title: "Revenue Reports",
        href: '/revenue/reports',
        icon: DashboardIcon
      },
      {
        title: "Expenses",
        href: '/expenses',
        icon: DashboardIcon
      },
      {
        title: "Super Orders",
        href: '/sorders',
        icon: DashboardIcon
      },
      {
        title: "Super Requests",
        href: '/srequests',
        icon: DashboardIcon
      },
      {
        title: "Employees",
        href: '/semployees',
        icon: DashboardIcon
      },
      {
        title: "My Account",
        href: '/saccount',
        icon: DashboardIcon
      },
      {
        title: "Mall Orders",
        href: '/morders',
        icon: DashboardIcon
      },
      {
        title: "My Account",
        href: '/maccount',
        icon: DashboardIcon
      },
      {
        title: "Client App",
        href: '/client',
        icon: DashboardIcon,
        items: [
          {
            title: "Clients",
            href: '/client/clients'
          },
          {
            title: "Orders",
            href: '/client/orders'
          },
          {
            title: "Categories",
            href: '/client/categories'
          },
          {
            title: "Products",
            href: '/client/products'
          },
          {
            title: "Options",
            href: '/client/options'
          },
        ]
      },
      {
        title: "Settings",
        href: '/settings',
        icon: DashboardIcon,
        items: [
          {
            title: "Countries",
            href: '/settings/countries'
          },
          {
            title: "Malls",
            href: '/settings/malls'
          },
          {
            title: "Areas",
            href: '/settings/areas'
          },
          {
            title: "Car Types",
            href: '/settings/car/types'
          },
          {
            title: "Supplies",
            href: '/settings/supplies'
          },
          {
            title: "Services",
            href: '/settings/services'
          },
          {
            title: "Service Types",
            href: '/settings/service/types'
          },
          {
            title: "Covers",
            href: '/settings/covers',
          },
          {
            title: "Area Supervisor",
            href: '/area/supervisor',
          },
          {
            title: "Client App Settings",
            href: '/settings/app'
          },
        ]
      },
      {
        title: "Warehouse",
        href: '/warehouse',
      },
      {
        title: "Supply Invoices",
        href: '/supply/invoice',
      },
      {
        title: "Mall Supply",
        href: '/mall/supply',
      },
      {
        title: "Used Supply",
        href: '/stock/used',
      },
      {
        title: "Damage Requests",
        href: '/stock/damage',
      },
      {
        title: "App Orders",
        href: '/app/orders',
      },
      {
        title: "Mall Stock",
        href: '/mall/stock',
      },
      {
        title: "Mall Damaged",
        href: '/mall/damaged',
      },
      {
        title: "Hand Over",
        href: '/hand/over',
      },
      {
        title: "User Management",
        href: '/user/management',
        icon: GroupAddOutlinedIcon,
        items: [
          {
            title: "Permissions",
            href: '/user/management/permissions'
          },
          {
            title: "Roles",
            href: '/user/management/roles'
          },
          {
            title: "Users",
            href: '/user/management/users'
          }
        ]
      }
    ]
  }
]
